import React, { Component } from "react";
import Layout from "../components/layout-partnership";
import "../css/sharan-style.css";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import ReactHtmlParser from "react-html-parser";

Swiper.use([Navigation, Pagination, Autoplay]);

class PartnerUniversities extends Component {
  homeOurUniPartLink = (e) => {
    const utabHead = e.target.parentNode;

    if (utabHead) {
      const universityPartnersSec = document.querySelector(
        ".home-university-partners-sec"
      );
      const viewMoreBtn = document.querySelector(".view-more-universities-btn");

      if (utabHead.classList.contains("active")) {
        utabHead.classList.remove("active");
        universityPartnersSec.classList.remove("active");
        viewMoreBtn.textContent = viewMoreBtn.textContent.replace(
          "Less",
          "More"
        ).replace("-", "+");
      } else {
        utabHead.classList.add("active");
        universityPartnersSec.classList.add("active");
        viewMoreBtn.textContent = viewMoreBtn.textContent.replace(
          "More",
          "Less"
        ).replace("+", "-");
      }
    }
  };

  componentDidMount() {
    new Swiper(".partner-swiper", {
      slidesPerView: 1,
      spaceBetween: 50,
      slidesPerGroup: 1,
      loop: false,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        1200: { slidesPerGroup: 6, slidesPerView: 6 },
        992: { slidesPerGroup: 4, slidesPerView: 4 },
        768: { slidesPerGroup: 2, slidesPerView: 2 },
      },
    });
  }

  render() {
    const {
      featuredUniversities,
      uniData,
      uniPartnerTitle,
      uniPartnerFooter,
    } = this.props;

    const featuredIds = featuredUniversities
      .split(",")
      .map((id) => id.trim())
      .filter((id) => id !== "")
      .map((id) => parseInt(id));

    const university_logo_slider = featuredIds
      .map((id) =>
        uniData.find((uni) => uni.wordpress_id === id)
      )
      .filter((uni) => uni !== undefined);

    return (


          <div className="half-section bg-white home-university-partners-sec align-items-center justify-content-center partner-sec partner-custom-styling newStyle">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 padding-1-rem-lr margin-two-bottom text-center wow animate__slideInLeft">
                  <h2 className="color-blue">{uniPartnerTitle}</h2>
                </div>
              </div>

   
                <div className="row home-university">
                  {university_logo_slider.slice(0, 6).map((image) => {
                    const linkUrl = image.acf.university_detail_page?.includes(
                      "yes"
                    )
                      ? `/university-detail/${image.slug}/`
                      : image.acf.website_url || "";

                    return (
                      <div
                        className="col-md-3 col-lg-2 col-6 margin-15px-bottom"
                        key={image.id}
                      >
                        <a
                          href={linkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="uniPartnerLogo"
                        >
                          <div className="uni-image-box">
                            <img
                              src={image.acf.university_logo.source_url}
                              alt={image.title
                                .replace(/&#8211;/g, "-")
                                .replace(/&#8217;/g, "'")}
                            />
                          </div>
                          <div className="uni-text-box">
                            <span className="w-100">
                              {image.title
                                .replace(/&#8211;/g, "-")
                                .replace(/&#8217;/g, "'")}
                            </span>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>

                <div className="row remaining-universities home-university-partners-detail">
                  {university_logo_slider.slice(6).map((image) => {
                    const linkUrl = image.acf.university_detail_page?.includes(
                      "yes"
                    )
                      ? `/university-detail/${image.slug}/`
                      : image.acf.website_url || "";

                    return (
                      <div
                        className="col-md-3 col-lg-2 col-6 margin-15px-bottom"
                        key={image.id}
                      >
                        <a
                          href={linkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="uniPartnerLogo"
                        >
                          <div className="uni-image-box">
                            <img
                              src={image.acf.university_logo?.source_url}
                              alt={image.title
                                .replace(/&#8211;/g, "-")
                                .replace(/&#8217;/g, "'")}
                            />
                          </div>
                          <div className="uni-text-box">
                            <span className="w-100">
                              {image.title
                                .replace(/&#8211;/g, "-")
                                .replace(/&#8217;/g, "'")}
                            </span>
                          </div>
                        </a>
                      </div>
                    );
                  })}

                  <div className="col-md-12 university-partners-footer">
                    <div className="text-smallest margin-20px-top last-paragraph-no-margin">
                      {ReactHtmlParser(uniPartnerFooter)}
                    </div>
                  </div>
                </div>

                <div className="col-12 text-center mt-4">
                  <a
                    onClick={this.homeOurUniPartLink}
                    className="view-more-universities-btn"
                  >
                    View More Universities +
                  </a>
                </div>
              </div>
            </div>


    );
  }
}

export default PartnerUniversities;
