import React, { Component } from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

import ReactHtmlParser from 'react-html-parser';


import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';


import PopupForm from "../components/popupForm";
import PopupVideo from "../components/popupVideo";


import { graphql } from "gatsby";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


import "../css/swiper-bundle.min.css";

Swiper.use([EffectCoverflow, Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Scholership extends Component {

	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};


	homeOurUniPartLink = (e) => {
		const utabHead = e.target.parentNode;

		if (utabHead) {
			const universityPartnersSec = document.querySelector('.home-university-partners-sec');
			if (utabHead.classList.contains('active')) {
				utabHead.classList.remove('active');
				universityPartnersSec.classList.remove('active');
				document.querySelector('.view-more-universities-btn').textContent = document.querySelector('.view-more-universities-btn').textContent.replace('Less', 'More').replace('-', '+');
	
			} else {
				utabHead.classList.add('active');
				universityPartnersSec.classList.add('active');
				document.querySelector('.view-more-universities-btn').textContent = document.querySelector('.view-more-universities-btn').textContent.replace('More', 'Less').replace('+', '-');;

			}
		}

	};

	handleClick(section) {
	
		const element = document.getElementById(section);


		const offset = 240;
		const elementPosition = element.getBoundingClientRect().top + window.scrollY;


		const isMobile = window.innerWidth <= 768; 

		if (isMobile) {
		
			const mobileSection = section + '-mobile';
			const mobileElement = document.getElementById(mobileSection);
		
			mobileElement.innerHTML = element.querySelector('.scholarship-content').innerHTML;
			mobileElement.classList.toggle('active');


		
			const activeElementsMobile = document.querySelectorAll('.active:not(#' + section + '-mobile)');

		
			activeElementsMobile.forEach(activeElementsMobile => {
				activeElementsMobile.classList.remove('active');
			});


		} else {
			element.classList.toggle('active');

			
			const activeElements = document.querySelectorAll('.active:not(#' + section + ')');

			
			activeElements.forEach(activeElement => {
				activeElement.classList.remove('active');
			});

			const offset = 240;
			const elementPosition = element.getBoundingClientRect().top + window.scrollY;

		}
	}

	handleUniversityClick(section) {
	
		var elements = document.getElementsByClassName('scholarship-uni');

		
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.remove('active');
		}

		const element = document.getElementById(section);
		element.classList.toggle('active');

		
		const otherSections = ['australia', 'uk', 'usa'].filter(
			(section) => section !== section
		);

		otherSections.forEach((otherSection) => {
			const otherElement = document.getElementById(otherSection);
			if (otherElement) {
				otherElement.classList.remove('active');
			}
		});
	}
	componentDidMount() {
		

	


		var getInTouchLinks = document.querySelectorAll('a[href="#get-in-touch"]');


		getInTouchLinks.forEach(function(link) {
			
		  link.addEventListener('click', function(event) {
			event.preventDefault();
			
			document.getElementById('book-an-appointment').click();
		  });
		});



		var swiper = new Swiper('.smartgrads, .alumni, .graduation-ceremony, .main-faculty, .faculties', {
			      centeredSlides: false,
			      loop: false,
			      slidesPerView: 1,
			      spaceBetween: 0,
			      slidesPerGroup: 1,
			      autoplay: {
			        delay: 7000,
			        disableOnInteraction: false
			      },
			      pagination: {
			        el: '.swiper-pagination-testimonial',
			        clickable: true,
			      },
			      navigation: {
			        nextEl: '.swiper-next-testimonial',
			        prevEl: '.swiper-prev-testimonial',
			      },
			      
			      breakpoints: {
			        1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20},
			        991: { slidesPerGroup: 2, slidesPerView: 2},
			        768: { slidesPerGroup: 1, slidesPerView: 1 }
			      }
			    });
	



	}
	render() {

		const post = this.props.data.allWordpressPage.edges[0].node;
		const metatitle = post.acf.meta_title;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase;
		const canonical_url =`https://theworldgrad.com${this.props.path}`;




		const students_educated_count = 86;
		const students_educated_text = "Program Completion Rate";
		const satisfaction_rating_text = "4.9/5 Feedback on teachers";
		const overseas_degrees_count = 1347;
		const overseas_degrees_text = "Hours of 1-1 support delivered";

		const activeStars = 5;
		

		var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
		if (post.featured_media) {
			headerBackground = post.featured_media.source_url;
		}
		return (
			<div className="about-page">
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
						<title>{metatitle}</title>
						<meta name="description" content={metadescription} />
						{(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
						<link rel="canonical" href={canonical_url} /> 
						{(() => { if (post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) { return (<script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>) } })()}
						
					</Helmet>



					<div class="newStyle font-poppin  scholarships students">
						<div className="container">
							<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
								<li class="breadcrumb-item">
									<a href="/">Home </a>
								</li>
								<li class="breadcrumb-item active">{post.title}</li>
							</ol>
						</div>
						<div id="scholarship-band" class="top-bar-style-two top-message font-weight-500 text-center text-white"><span>{post.acf.banner_section.text_1}</span></div>
						<section class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-25px-top sm-padding-30px-bottom  position-relative scholar-banner bg-cover345br " style={{ backgroundImage: `url(${post.acf.banner_section.background_image?.source_url})` }}
						>

							<div class="container">
								<div class="row">
									<div class="col-7 col-xl-9 col-sm-9 col-md-9 position-relative page-title-large ">

										<h2 class="yellow-background font-weight-500 alt-font  letter-spacing-1px text-black text-uppercase margin-20px-bottom d-none-991">{ReactHtmlParser(post.acf.banner_section.text_1)}</h2>

										<h1 class="text-white alt-font font-weight-500 letter-spacing-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-15px-bottom text-uppercase">{ReactHtmlParser(post.acf.banner_section.text_2)}</h1>

										<h2 class="alt-font font-weight-300 text-white margin-30px-bottom sm-margin-10px-bottom small-h2 " >{ReactHtmlParser(post.acf.banner_section.text_3)}</h2>


					


									</div>
								</div>
							</div>
							
						</section>
						<section class="p-0">
  <div class="col-12 col-md-12 alert bg-fast-blue news-ticker">
  <div class="news-content">
  {[...Array(5)].map((_, iteration) => (
    <React.Fragment key={iteration}>
      {post.acf.news_ticker.map((item, index) => (
        <React.Fragment key={index}>
          {item.news_title && <strong>{item.news_title}</strong>} {item.news_content}
          {index < post.acf.news_ticker.length - 1 && <span className="yellowbullet"></span>}
        </React.Fragment>
      ))}
      {iteration < 4 && <span className="yellowbullet"></span>}
    </React.Fragment>
  ))}
</div>
  </div>
</section>
						<section class="padding-50px-top pb-0">
							<div class="container">
								<div class="row">
								<div class="col-12 text-center col-lg-12 col-sm-12">
									
				

									<h2 class="color-blue">{post.acf.students_intro_section.intro_title}</h2>
									{ReactHtmlParser(post.acf.students_intro_section.intro_content)}
									
									
								</div>


									
									
								</div>




								<div class="row  text-center ">

              
                  <div class="col-12 col-md-4 col-sm-12 text-center sm-margin-30px-bottom wow animate__fadeIn" data-wow-delay="0.3s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                      <h3 class="vertical-counter d-inline-flex alt-font appear font-weight-600 color-blue mb-0 sm-letter-spacing-minus-1-half" data-to={post.acf.students_intro_section.number_for_text_1} ><CountUp end={post.acf.students_intro_section.number_for_text_1} redraw={true} >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp></h3><span class="color-blue title-extra-small font-weight-500 margin-5px-left">%</span>
                    </div>
                    <span class="alt-font d-block">{ReactHtmlParser(post.acf.students_intro_section.image_text_1)}</span>
                  </div>
                  <div class="col-12 col-md-4 col-sm-12 text-center wow animate__fadeIn sm-margin-30px-bottom" data-wow-delay="0.6s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                      <div class="homestar-rating">
  <span class="homestar"></span>
  <span class="homestar"></span>
  <span class="homestar"></span>
  <span class="homestar"></span>
  {parseInt(post.acf.students_intro_section.number_for_text_2, 10) > 4  && <span className="homestar"></span>}
</div>
                    </div>
                    
    
                    <span class="alt-font d-block">{ReactHtmlParser(post.acf.students_intro_section.image_text_2)}</span>
                  </div>
				  <div class="col-12 col-md-4 col-sm-12 text-center   wow animate__fadeIn" data-wow-delay="0.2s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                      <h3 class="vertical-counter d-inline-flex alt-font appear font-weight-600 color-orng  mb-0 sm-letter-spacing-minus-1-half" data-to={post.acf.students_intro_section.number_for_text_3} ><CountUp end={post.acf.students_intro_section.number_for_text_3} redraw={true} >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp></h3><span class="text-fast-blue title-extra-small color-orng font-weight-500 margin-5px-left">+</span>
                    </div>
                    <span class="alt-font d-block">{ReactHtmlParser(post.acf.students_intro_section.image_text_3)}</span>
                  </div>


          

									
									
								</div>

								<div class="row margin-20px-top text-left ">
							

							

					

									
									
								</div>
								



							</div>
							<div class="student-disclaimer">
							{ReactHtmlParser(post.acf.students_intro_section.disclaimer)}
						
</div>				</section>

						<section class="padding-30px-tb scholarship-block bg-fast-yellow ">
							<div class="container">
								<div class="row ">
									<div class="col-12  text-center  ">
										<div class="no-margin-bottom sm-margin-20px-top">
											<h2  >{post.acf.cta_1.cta_title}
</h2>
											
										</div>
									
										<a
											data-animation="animated zoomInUp"
											class="btn btn-theme effect btn-theme-rev btn-theme-rev-orng btn-md   margin-10px-tb modal-popup "
											href={post.acf.cta_1.cta_button_link_new?.source_url}>{post.acf.cta_1.cta_button_text}</a>
										
									</div>
								</div>
							</div>
						</section>

						<section id="about" class="padding-50px-tb bg-light-gray wow animate__fadeIn">
							<div class="container">

							<div class="row margin-30px-bottom sm-margin-5px-bottom">
									<div class="col-12 text-center col-lg-12 col-sm-12 ">
									<h2 class="margin-10px-bottom color-blue">{post.acf.smartgrads.smartgrads_title}</h2>
									<p class="mb-0 yellowbg text-white margin-5px-bottom font-21px">{post.acf.smartgrads.smartgrads_subtext}</p>
	
									</div>
								</div>

							<div class="row justify-content-center padding-15px-right">
									<div class="col-12 col-xl-6 col-lg-6 col-md-12 md-margin-one-bottom ">
										<div class="col-12 p-0  wow animate__slideInLeft" >
											<div class="w-90 md-w-100  text-justify " >
												<div class="no-margin-bottom program-content" />
												
												<div class=" d-show-991 position-relative text-center text-lg-left"><PopupVideo time="9999999999" videoURL={post.acf.smartgrads.smartgrads_video_url} /> <img src={post.acf.smartgrads.smartgrads_image.source_url} alt=""  class="margin-20px-bottom"/></div>
												{ReactHtmlParser(post.acf.smartgrads.smartgrads_content)}
												

											</div>

										</div>

									</div>


									<div class="animate__slideInRight animated bg-cover sm-margin-10px-top sm-margin-40px-bottom br-15 col-12 col-lg-6 margin15  mt-lg-0  mx-0 p-0 position-relative wow d-none-991" data-wow-delay="0.1s"  style={{ backgroundImage: `url(${post.acf.smartgrads.smartgrads_image.source_url})` }}   >
									<PopupVideo time="9999999999" videoURL={post.acf.smartgrads.smartgrads_video_url} /><img src={post.acf.smartgrads.smartgrads_image.source_url} alt="Student Visa" data-no-retina="" class="br-15 margin15 opacity-0 " />
										


									</div>
									
								</div>
				
	  <div class="text-center padding-20px-top center-justified">
								{ReactHtmlParser(post.acf.smartgrads.extra_content)}
								</div>
								<div class="row  sm-margin-5px-top margin-10px-bottom">

							

									<div class="col-md-12">
										<div class="swiper-container swiper-style-new-design smartgrads">
										<div className="swiper-wrapper">											

    {post.acf.smartgrads.smartgrads_image_slider.map((slide, index) => (
      <div key={index} className="swiper-slide desktop">
        <img src={slide.image.source_url} alt={post.acf.smartgrads.smartgrads_subtext} className="border-radius-8px" />
      </div>
    ))}


										</div>
										<div className="swiper-pagination swiper-pagination-testimonial"></div>
									</div>					
									</div>
									<div class="col-md-12 text-center margin-20px-top">
									<p class="margin-10px-bottom center-justified"><strong>{post.acf.smartgrads.smartgrads_testimonial}</strong></p>
									
									<p  class="mb-0 yellowbg "><strong>{post.acf.smartgrads.smartgrads_testimonial_by}</strong></p>
									</div>
             					 </div>		

							</div>
						</section>

						<section class="padding-50px-tb bg-white home-university-partners-sec">
							<div class="container">
								<div class="row ">
									<div class="col-12 text-center col-lg-12 col-sm-12 margin-15px-bottom">
										<h2 class="color-blue">{post.acf.alumni_title}</h2>


									</div>
								</div>
								<div class="home-university">
								<div class="row justify-content-center  text-center">
								<div class="col-md-12">
										<div class="swiper-container swiper-style-new-design alumni">
										<div className="swiper-wrapper">											

    {post.acf.alumni_slider.map((slide, index) => (
      <div key={index} className="swiper-slide desktop">
        <PopupVideo time="9999999999" videoURL={slide.video_url} />
        <img src={slide.image.source_url} alt={post.acf.alumni_title} className="border-radius-8px" />
      </div>
    ))}

										</div>
										<div className="swiper-pagination swiper-pagination-testimonial"></div>
									</div>					
									</div>
								</div>				
							</div>
							</div>
						</section>

						<section class="padding-50px-tb bg-light-gray wow animate__fadeIn">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-md-12 text-center margin-two-bottom"><h2 class="color-blue">{post.acf.alumni_events_title}</h2></div>
								</div>


								<div class="row  justify-content-center  text-center">
								<div class="col-md-12">
									
										<div class="swiper-container swiper-style-new-design graduation-ceremony">
										<div className="swiper-wrapper">											
										{post.acf.alumni_events_slider.map((event, index) => (
      <div key={index} className="swiper-slide desktop">
		<div className="position-relative">

	  <PopupVideo time="9999999999" videoURL={event.video_url} />
        <img src={event.image.source_url} alt={post.acf.alumni_events_title} className="border-radius-8px margin-10px-bottom"  style={{ height: '210px', width: '370px' }} />
		</div>
        <p class="text-center">
         {ReactHtmlParser(event.text)}
        </p>
      </div>
    ))}
										</div>
										<div className="swiper-pagination swiper-pagination-testimonial "></div>
									</div>					
									</div>

									
								</div>			

								</div>
							
						</section>


						<section class="padding-50px-tb  wow animate__fadeIn">

							<div class="container">
								<div class="row justify-content-center">
									<div class="col-md-12 text-center margin-two-bottom"><h2 class="color-blue">{post.acf.fun_zone.fun_zone_title}</h2></div>
								</div>


								<div class="row  justify-content-center  text-center">
								<div class="col-md-12">
									
										<div class="swiper-container swiper-style-new-design graduation-ceremony">
										<div className="swiper-wrapper">											
										{post.acf.fun_zone.fun_zones.map((fun, index) => (
      <div key={index} className="swiper-slide desktop">

        <img src={fun.image.source_url} alt={fun.title} className="border-radius-8px margin-10px-bottom" />
        <p class="text-center">
         {ReactHtmlParser(fun.title)}
        </p>
      </div>
	  
    ))}
	
										</div>
										<div className="swiper-pagination swiper-pagination-testimonial "></div>
									</div>					
									</div>

									
								</div>			

								</div>
							
						</section>

						<section class="padding-50px-tb wow animate__fadeIn bg-light-gray">
							<div class="container">

							<div class="row justify-content-center padding-15px-right">
									<div class="col-12 col-xl-6 col-lg-6 col-md-12 md-margin-one-bottom ">
										<div class="col-12 p-0  wow animate__slideInLeft" >
											<div class="w-90 md-w-100 text-justify " >
												<div class="no-margin-bottom program-content" />
												
												<div class="text-center text-lg-left">
												<h2 class="margin-10px-bottom color-blue">{post.acf.faculty.faculty_title}</h2>
												<p class="yellowbg text-white margin-5px-bottom ">{post.acf.faculty.faculty_subtext}</p>
												</div>
									
								
									<div class=" d-show-991 position-relative text-center text-lg-left"><PopupVideo time="9999999999" videoURL={post.acf.faculty.faculty_video_url} /> <img src={post.acf.faculty.faculty_image.source_url} alt=""  class="margin-20px-bottom"/></div>
												
								

										
									{ReactHtmlParser(post.acf.faculty.faculty_content)}
											</div>

										</div>

									</div>


									<div class="animate__slideInRight animated bg-cover sm-margin-10px-top sm-margin-40px-bottom br-15 col-12 col-lg-6 margin15  mt-lg-0  mx-0 p-0 position-relative wow d-none-991" data-wow-delay="0.1s"  style={{ backgroundImage: `url(${post.acf.faculty.faculty_image.source_url})` }}   >
									<PopupVideo time="9999999999" videoURL={post.acf.faculty.faculty_video_url} /><img src={post.acf.faculty.faculty_image.source_url} alt="Student Visa" data-no-retina="" class="br-15 margin15 opacity-0 " />
										


									</div>
									
								</div>

							

							


								<div class="row margin-30px-top sm-margin-5px-top">

						

									<div class="col-md-12 d-none">
										<div class="swiper-container swiper-style-new-design main-faculty">
										<div className="swiper-wrapper">											
										{post.acf.faculty.faculty_image_slider.map((faculty, index) => (
      <div key={index} className="swiper-slide desktop">
        {faculty.video_link && <PopupVideo time="9999999999" videoURL={faculty.video_link} />}
        <img src={faculty.image.source_url} alt="#" className="border-radius-8px" />
      </div>
    ))}

											
										</div>
										<div className="swiper-pagination swiper-pagination-testimonial"></div>
									</div>					
									</div>
									<div class="col-md-12  text-center ">
									{ReactHtmlParser(post.acf.faculty.faculty_more_text)}
									
									</div>
             					 </div>		

							</div>
						</section>

						<section class="padding-50px-bottom pt-0 bg-light-gray home-university-partners-sec">
							<div class="container">
								<div class="row ">
									<div class="col-12 text-center col-lg-12 col-sm-12 margin-15px-bottom">
										<h2 class="color-blue">{post.acf.hear_from_our_faculty_title}</h2>


									</div>
								</div>
								<div class="home-university text-center">
								<div class="row">
								<div class="col-md-12">
										<div class="swiper-container swiper-style-new-design faculties">
										<div className="swiper-wrapper">											
										{post.acf.hear_from_our_faculty_slider.map((faculty, index) => (
      <div key={index} className="swiper-slide desktop">
        {faculty.video_url && <PopupVideo time="9999999999" videoURL={faculty.video_url} />}
        <img src={faculty.image.source_url} alt="#" className="border-radius-8px" />
        <p class="text-center">{faculty.faculty_name}</p>
      </div>
    ))}
										</div>
										<div className="swiper-pagination swiper-pagination-testimonial"></div>
									</div>					
									</div>
								</div>				
							</div>
							</div>
						</section>

						<section class="padding-50px-top   padding-25px-bottom wow animate__fadeIn ">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-md-12 text-center margin-two-bottoms"><h2 class="color-blue">{post.acf.case_study_title}</h2>
									{ReactHtmlParser(post.acf.case_study_subtext)}
									</div>
								</div>


								<div className="row justify-content-center text-center">
  {post.acf.case_studies.map((study, index) => (
    <div key={index} className="col-md-4">
      {study.link_new?.source_url ? (
        <a href={study.link_new?.source_url} target="_blank">
          <img src={study.image.source_url} alt="#" className="border-radius-8px margin-10px-bottom" />
        </a>
      ) : (
        <img src={study.image.source_url} alt="#" className="border-radius-8px margin-10px-bottom" />
	  )}
      <p class="text-center"><a class="btn btn-theme effect btn-md" href={study.link_new?.source_url} target="_blank">{study.title}</a></p>
   
    </div>
  ))}
</div>

										
													
									

								</div>
							
						</section>
				
						<section class="padding-30px-tb bg-fast-yellow wow animate__fadeIn scholarship-block">
							<div class="container">
								<div class="row">
									<div class="col-md-10 offset-md-1 text-center ">
										<h2 >{post.acf.cta_2.cta_2_title}</h2>
									</div>
								</div>
								<div class="row justify-content-center">
									<div class="col-12 col-lg-10 wow animate__fadeIn text-center">

										
										<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-theme-rev btn-theme-rev-orng btn-md" href={post.acf.cta_2.cta_2_button_link}>{post.acf.cta_2.cta_2_button_text}
</a>
									</div>


								</div>
							</div>
						</section>

					</div>

				</Layout>
			</div>
		);
	}
}
export default Scholership;
export const ScholershipPage = graphql`
    {
		allWordpressPage(filter: {wordpress_id: {in: 18871}}) {
			edges {
			  node {
				wordpress_id
				title
				featured_media {
				  source_url
				}
				content
				acf {
					meta_title
					meta_description
					focus_keyphrase
					canonical_url
					dynamic_schema
				  banner_section {
					text_1
					text_2
					text_3
					button_link
					button_text
					background_image {
					  source_url
					}
				  }
				 
				  news_ticker {
					news_content
					news_title
				  }
				  students_intro_section {
					image_text_1
					image_text_2
					image_text_3
					number_for_text_1
					number_for_text_2
					number_for_text_3
					intro_content
					
					
					intro_title
					image_1 {
					  source_url
					}
					image_2 {
					  source_url
					}
					image_3 {
					  source_url
					}
					disclaimer
				  }
				  cta_1 {
					cta_button_link_new {
					source_url
					}
					cta_button_text
					cta_title
				  }
				  cta_2 {
					cta_2_button_link
					cta_2_button_text
					cta_2_title
				  }
				  smartgrads {
					smartgrads_content
					extra_content
					smartgrads_image {
					  source_url
					}
					smartgrads_image_slider {
					  image {
						source_url
					  }
					}
					smartgrads_subtext
					smartgrads_testimonial
					smartgrads_testimonial_by
					smartgrads_title
					smartgrads_video_url
				  }
				  alumni_title
				  alumni_slider {
					video_url
					image {
					  source_url
					}
				  }
				  alumni_events_title
				  alumni_events_slider {
					text
					video_url
					image {
					  source_url
					}
				  }
					fun_zone{
  fun_zone_title
  fun_zones{
    image 
	{
	source_url
	}
    title
}
	}

				  faculty {
					faculty_title
					faculty_video_url
					faculty_subtext
					faculty_more_text
					faculty_content
					faculty_image {
					  source_url
					}
					faculty_image_slider {
					  image {
						source_url
					  }
					  video_link
					}
				  }
					
				  hear_from_our_faculty_title
				  hear_from_our_faculty_slider {
					faculty_name
					video_url
					image {
					  source_url
					}
				  }
				  case_study_title
				  case_study_subtext
				  case_studies {
					title
					image {
						source_url
					}
					link_new  {
					source_url
					}
				  }
				}
			  }
			}
		  }
}
`
