import React, { Component } from "react";
import { graphql } from "gatsby";
import axios from "axios";
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';


import PopupForm from "../components/popupForm";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";


import ReactDOM from 'react-dom';

import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Sharing from "./../components/Sharing";
import TableOfContent from "./../components/TableOfContent";
import PopularDegreeOutcome from "../components/popular-degree-outcome-fields";
class StudyResource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
      programUrl: props.path,
      displayLinks: [], 
		};
	}
	componentDidMount() {
		this.fetchDisplayLinks().then(() => {
		  this.insertRandomDiv();
		});


const preElements = document.querySelectorAll('pre');


preElements.forEach((preElement) => {

  if (preElement.innerHTML.startsWith('[slider')) {

    const titleMatch = preElement.innerHTML.match(/title="([^"]+)"/);
    

    if (titleMatch) {
      //console.log("Effects Dump ->", titleMatch[1]);
	  this.fetchSliders(titleMatch[1])
    }
  }
});


		  }
		  


  
  fetchDisplayLinks = async () => {
    try {
      const response = await axios.post("https://assets.theworldgrad.com/graphql", {
        query: `
          query {
            displayLinks {
              edges {
                node {
                  id
                  title
				  featuredImage {
          node {
            sourceUrl
          }
        }
                  DisplayLinkDetails {
                    linkTo
                    youtubeEmbedCode
                    sidebarImage {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        `,
      });

      if (response.data && response.data.data) {
        this.setState({ displayLinks: response.data.data.displayLinks.edges });
      }
    } catch (error) {
      console.error("Error fetching display links:", error);
    }
  };
  async fetchSliders(title) {
	try {
	  const response = await axios.post("https://assets.theworldgrad.com/graphql", {
		query: `
		  query {
			sliders(where: { title: "${title}" }) {
			  edges {
				node {
				  id
				  title
				  featuredImage {
					node {
					  sourceUrl
					}
				  }
				  program_list {
					programList {
					  programs {
						... on ProgramList {
						  id
						  title
						  slug
						  programList {
							country
							degreeType
							universityRanking
							    selectUniversity {
                    ... on University {
                      slug
                    }
                    }
						  }
						  universityImage {
							universityLogo {
							  sourceUrl
							}
						  }
						  universityPicture {
							universityProfilePicture {
							  sourceUrl
							}
						  }
						}
					  }
					}
				  }
				}
			  }
			}
		  }
		`,
	  });
	  //console.error("Effects Dump -> ", response.data.data.sliders.edges);

	  const transformedData = response.data.data.sliders.edges.flatMap(item => 
		item.node.program_list.programList.map(program => ({
		  degree_name: program.programs.title,
		  degree_banner: {
			source_url: program.programs.universityPicture.universityProfilePicture.sourceUrl
		  },
		  college_university_name: program.programs.programList.universityRanking,
		  college_university_logo: {
			source_url: program.programs.universityImage.universityLogo.sourceUrl
		  },
		  country_name: program.programs.programList.country,
		  url: `/program-detail/${program.programs.programList.country.replace(/\s+/g, "-").toLowerCase()}/${program.programs.programList.selectUniversity.slug}/${program.programs.programList.degreeType === 'Undergraduate' ? 'ug' : 'pg'}/${program.programs.slug}/`

		}))
	  );
	  
	  // console.log("Effects Dump -> ", transformedData);
	  
	  this.replaceTextWithComponent(transformedData, title);


	} catch (error) {
	  console.error("Error fetching sliders:", error);
	}
  }
  

  replaceTextWithComponent(slidersData, title) {

	const preElements = document.querySelectorAll('pre');
  
	preElements.forEach((preElement) => {

	  if (preElement.innerHTML.startsWith('[slider')) {
	
		const titleMatch = preElement.innerHTML.match(/title="([^"]+)"/);
		console.log("Effects Dump Replace ->", titleMatch);
  
	
		if (titleMatch && titleMatch[1] === title) {
	
			const newDiv = document.createElement('div');
			newDiv.className = 'newStyle blog-program-slider';

			ReactDOM.render(
			  React.createElement(PopularDegreeOutcome, { degrees: slidersData }),
			  newDiv
			);
  
	
		  preElement.parentNode.replaceChild(newDiv, preElement);
		}
	  }
	});
  }
  

  
  


  insertRandomDiv = () => {
    const { displayLinks } = this.state;
    const { content } = this.props.data.wordpressWpBlog.acf; 

    if (displayLinks.length > 0) {
      const randomLink = displayLinks[Math.floor(Math.random() * displayLinks.length)];
      const { title, featuredImage, DisplayLinkDetails } = randomLink.node;

   

	  
	  const newDivContent = `
      <div class="random-content-block">
        <h6>${title}</h6>
        ${
          DisplayLinkDetails?.youtubeEmbedCode
            ? `<iframe width="100%" height="auto" src="${DisplayLinkDetails.youtubeEmbedCode}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
            : `<a href="${DisplayLinkDetails?.linkTo || "#"}" target="_blank" rel="noopener noreferrer">
                 <img src="${featuredImage?.node?.sourceUrl || ""}" alt="${title}" />
               </a>`
        }
      </div>
    `;

 
      const contentParts = content.split("</p>");
      const randomIndex = Math.floor(Math.random() * contentParts.length);
      contentParts.splice(randomIndex, 0, newDivContent);

	  const randomChoice = Math.random() < 0.5; 
	  if (randomChoice) {

		const contentParts = content.split("</p>");
		const randomIndex = Math.floor(Math.random() * contentParts.length);
		contentParts.splice(randomIndex, 0, newDivContent);
		this.setState({ contentWithLink: contentParts.join("</p>") });
	  } else {
		this.setState({ contentWithLink: content });
		this.setState({ randomDiv: newDivContent });
	  }


    } else {
      this.setState({ contentWithLink: content });
    }
  };

  

  

  handlePopupWindow = () => {
    this.setState({ time: Math.random() });
  };

	render() {
		const updateH3 = (htmlContent) => {
			if (!htmlContent) {
			  console.log("HTML content is empty or null:", htmlContent);
			  return "";
			}
		  
	
		  
			const updatedContent = htmlContent.replace(
				/<p><strong>(Q\.\d+ [^<]+|Q\d+\..*?)<\/strong><br\s*\/?>\s*(A\.\d+ [^<]+|A\d+\..*?)<\/p>/g, 
				(_, question, answer) => {
	
				  return `<h3 class="small-heading">${question}</h3><p>${answer}</p>`;
				}
			  );
			  
			  return updatedContent;
		  
	
		  
			return updatedContent;
		  };
		  

		var filteredRes = [];
		const post = this.props.data.wordpressWpBlog;
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description; 
	const focus_keyphrase = post.acf.focus_keyphrase; 
	const canonical_url = `https://theworldgrad.com${this.props.path}`; 
		this.props.data.wordpressWpBlog.blog_categories.forEach((element) => {
			this.props.data.allWordpressWpBlog.edges.forEach((data) => {
				if (filteredRes.length < 4) {
					if (data.node.blog_categories.includes(element) && filteredRes.includes(data) == false) filteredRes.push(data);
				}
			});
		});

		const author = post.author;

		const authorName =author.name;
		const authorBio = author.description;
		const authorPic = post.author && post.author.acf && post.author.acf.profile_picture ? post.author.acf.profile_picture.source_url : "https://assets.theworldgrad.com/wp-content/uploads/2024/03/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-vector.jpg";



	 const wordsPerMinute = 200;
	 const wordCount = post.acf.content ? post.acf.content.split(/\s+/).length : 0;
	 const calculatedMinutes = Math.ceil(wordCount / wordsPerMinute)+1;
	 const calculatedReadingTime = `${calculatedMinutes} ${calculatedMinutes === 1 ? "minute" : "minutes"}`;
	 const readingTime =  calculatedReadingTime;
	 const pageURL =  `https://theworldgrad.com${this.state.programUrl}`;


const imageUrls = [];


const extractImageUrls = (content) => {
	const imgRegex = /<img[^>]+src=['"]([^'">]+)['"]/gi;
	const imageUrls = [];
	let match;
	
	if (post.featured_media && post.featured_media.source_url) {
		imageUrls.push(post.featured_media.source_url);
	  }


	while ((match = imgRegex.exec(content)) !== null) {
	  const url = match[1];
	  if (!imageUrls.includes(url)) {
		imageUrls.push(url);
	  }
	}
	return imageUrls;
  };

  const extractedImageUrls = extractImageUrls(post.acf.content);

  function formatDate(date, ISO = false) {

	if (!(date instanceof Date)) {
	  date = new Date(date);
	}
  

  

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
	
  

	if (ISO) {
	  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
	} else {
	  return `${day}-${month}-${year}`;
	}
  }



	 const schema = `{
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"mainEntityOfPage": {
		  "@type": "WebPage",
		  "@id": "${pageURL}"
		},
		"headline": "${post.title}",
		"name": "${post.title}",
 "image": ${JSON.stringify(extractedImageUrls)},
 
 
		"datePublished": "${formatDate(post.date, true)}",  
		"dateModified": "${formatDate(post.modified, true)}",  
		"author": {
		  "@type": "Person",
		  "name": "${authorName || 'The WorldGrad'}",  
		  "url": "${authorPic || 'https://assets.theworldgrad.com/gatsby-assets/images/logo.png'}"  
		},
		"publisher": {
		  "@type": "Organization",
		  "name": "The WorldGrad",
		  "logo": {
			"@type": "ImageObject",
			"url": "https://assets.theworldgrad.com/gatsby-assets/images/logo.png" 
		  }
		},
		"hasPart": []
	  }
	  `;
	
		return (
			<div>
				<Layout>
					<Helmet>
					<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
		  <meta name="author" content={authorName} />

<meta property="og:type" content="article" />
<meta property="og:title" content={metatitle} />
<meta property="og:description" content={metadescription} />
<meta property="og:image" content={post.featured_media?.source_url} />
<meta property="og:image:width" content="1024" />
<meta property="og:image:height" content="640" />
<meta property="og:image:type" content="image/png" />
<meta property="og:url" content={`https://www.theworldgrag.com/study-resources/${post.slug}`} />
<meta property="og:site_name" content="The WorldGrad" />

<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content={metatitle} />
<meta name="twitter:description" content={metadescription} />
<meta name="twitter:image" content={post.featured_media?.source_url} />
<meta name="twitter:site" content="@TWorldgrad" />


		  <script type="application/ld+json">
                        
                        {JSON.stringify(JSON.parse(schema))}
                    
                    </script>





          


					</Helmet>

					<PopupForm time={this.state.time} />
					<div className="container newStyle">
						<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
							<li class="breadcrumb-item">
								<a href="/">Home</a>
							</li>
							<li class="breadcrumb-item">
								<a href="/study-resources/">Study Resources</a>
							</li>
							<li class="breadcrumb-item active">{ReactHtmlParser(post.title)}</li>
						</ol>
					</div>



					<div className="container-fluid blog-detail-container">
						<div className="row blog-detail-main-heading-text-row">
							{post.featured_media != null ? (
								<div className="col-12">
									<div className="blog-banner-img">
										<img src={post.featured_media.source_url} className="mb-3" alt={ReactHtmlParser(post.title)} />
									</div>
								</div>
							) : (
								""
							)}
							<div className="col-md-6 col-lg-10">
								<h1 className="blog-detail-main-heading-text">{ReactHtmlParser(post.title)}</h1>
								<div className="my-1 d-flex flex-wrap">
									<span className="blog-detail-sub-heading">
										Reading Time <strong>{readingTime  || "0"}</strong>
									</span>
									<span className="mx-3 text-muted">/</span>
									<span className="blog-detail-sub-heading">
						
									{post.blog_categories.includes(21) ? (
										<span>
										Published on <strong>{formatDate(post.date)}</strong>
									  </span>
  
) : (
	<span>
    Updated on <strong>{post.modified ? formatDate(post.modified) : formatDate(post.date)}</strong>
  </span>
)}
</span>
								</div>
								<div className="social-share-wrapper mt-3">
									<Sharing />
								</div>
							
							</div>
						</div>
						<div className="row mx-0">
							<div className="card shadow w-100 blog-detail-main-card">
						
								<div className="row blog-detail-card-content mt-10 align-items-start">
									<div className="col-md-8 blog-detail-topic-body">
										<p className="blog-detail-topic-para-text">{ReactHtmlParser(post.acf.description)}</p>
										<TableOfContent />
										<p className="blog-detail-topic-para-text">
										{ReactHtmlParser(updateH3(this.state.contentWithLink || post.acf.content))}

</p>

<div className="container-fluid blog-detail-topic-para-text">
  <div className="row">

	<h2 style={{ fontWeight: 400 }}>{post.acf.faq_title}</h2>

 
    {post.acf.faqs?.map((faq, index) => {
   const modifiedAnswer = faq.answer.replace(
	/<p>/, 
	`<p>A${index + 1}. `
  );
      return (
        <div key={`q-${index}`}>
          <h3 className="small-heading">{`Q${index + 1}. ${faq.question}`}</h3>
          {ReactHtmlParser(modifiedAnswer)}
        </div>
      );
    })}
  </div>
</div>



						<div class="container"	>					
<div class="row author-box">
<div className="col-md-2  author-image">
 <img 
        src={authorPic}
        alt={authorName}  
    /><br/>
										
			

</div>
<div className="col-md-10  author-description">

<p class=" font-weight-600 font-size-18 mb-2">Author: <span class="color-blue">{authorName}</span></p>
									<p>{authorBio}</p>
								</div>


</div>

									</div>

		</div>
	
		
	

									<div className="col-md-4 blog-detail-sidebar-wrapper">
										<div className="row blog-detail-side-menu">
											<div className="col-sm-6 col-md-12 mb-4">
												<div className="card shadow blog-detail-similar-blogs-card">
													<p className="blog-detail-similar-blogs"> Similar Blogs </p>
													{filteredRes.map(({ node }, index) => {
														return (
															<div>
																<a href={`/study-resources/${node.slug}/`}>
																	<p className="blog-detail-similar-blogs-title"> {ReactHtmlParser(node.title)} </p>
																</a>
																<p className="blog-detail-similar-blogs-posted-date">
																{node.blog_categories.includes(21) ? (
										<span>
										Published on  {node.date}
									  </span>
  
) : (
	<span>
    Updated on {node.modified ? node.modified : node.date}
  </span>
)}</p>
																{index != filteredRes.length - 1 && <hr style={{ color: "#626E81" }} />}
															</div>
														);
													})}

													
												</div>
											</div>
											
										</div>
										<div className="random-content-block-wrapper">
										<div dangerouslySetInnerHTML={{ __html: this.state.randomDiv }} />
              </div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				
				</Layout>
			</div>
		);
	}
}

export default StudyResource;
export const WorldgradBlogQuery = graphql`
	query currentWorldgradBlogQuery($id: String!) {
		wordpressWpBlog(id: { eq: $id }) {
			id
			title
			slug
			blog_categories
		 date(formatString: "YYYY-MM-DDTHH:mm:ss.sss")
  modified(formatString: "YYYY-MM-DDTHH:mm:ss.sss")
			featured_media {
				source_url
			}
			acf {
	
				description
				meta_title
meta_description
focus_keyphrase
canonical_url
		faq_title
faqs{
  question
  answer
}
				content
				dynamic_schema
			}
				
			author {
				description
				name
				acf {
				  profile_picture {
					source_url
				  }
				}
			  }
			}
		allWordpressWpBlog {
			edges {
				node {
					id
					title
					slug
					blog_categories
					date(formatString: "DD-M-YYYY")
					modified(formatString: "DD-M-YYYY")
				}
			}
		}
	}
`;


