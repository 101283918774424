import React, { Component } from "react";
import Layout from "../components/layout";
import axios from "axios";
import ProgramInfoCommon from "../components/program-listing-info-common-ug";
import { Helmet } from "react-helmet";
import "../css/style.css";
import "../css/sharan-style.css";
import PopupForm from "../components/popupForm";

import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';

const _ = require(`lodash`);

class ProgramListingArchive extends Component {
  constructor(props) {
    super(props);

    const { countries, degreeTypes, totalPrograms, uniTotal, studyAreas, countriesTest} = props.pageContext;


    this.state = {
      currentCat: {
        content: `Page=1`,  // Using template literal for dynamic string
        id: 1,  // Example photo, you can adjust as needed
      },
      displayedPrograms: [],
      loading: false,
      hasNextPage: true,
      hasPreviousPage: false,
      endCursor: null ,
      startCursor: null,
      currentPage: 1,
      countries,
      degreeTypes,
      studyAreas,
      selectedCountries: [],
      selectedDegreeTypes: [],
      selectedStudyAreas: [],
      totalPrograms,
      initialLoad: true, 
      uniTotal, 
      searchTerm: "",
      countriesTest 

    };
  }
 

  fetchEndCursor = async () => {
    const query = `
      query testi {
        allProgramList(
          first: 12
          where: { orderby: { field: MENU_ORDER, order: ASC } }
        ) {
          pageInfo {
            endCursor
          }
        }
      }
    `;

    try {
      const response = await axios.post('https://assets.theworldgrad.com/graphql', {
        query
      });

      const endCursor = response.data.data.allProgramList.pageInfo.endCursor;
      //console.log("End Cursor:", endCursor);


      this.setState({ endCursor }); 

      //console.log("Effects Dump - Pagination Executing query:", endCursor)

    } catch (error) {
 
    }
  };

  updateContent = (cat) => {
    this.setState({ currentCat: cat });
    window.history.pushState(cat, cat.content, `/program-listing/?${cat.content.toLowerCase()}`);
  };

  handleLinkClick = (event, catKey, direction) => {
    event.preventDefault();
    this.setState({ previousState: event.state });
    this.updateContent({
      content: `Page=${catKey}`,  // Using template literal for dynamic string
      id: catKey,  // Example photo, you can adjust as needed
    });
    if(direction == "next")
    this.handleNext()
  else if(direction == "previous")
    this.handlePrevious()
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const keyParam = decodeURIComponent(urlParams.get('key'));
  
    if (keyParam !== "null" && keyParam.length > 3) {
      this.handleKey(keyParam);
    } else if (!this.state.searchTerm && this.state.selectedCountries.length === 0 && this.state.selectedDegreeTypes.length === 0 && this.state.selectedStudyAreas.length === 0) {
      this.setState({ totalPrograms: this.props.pageContext.totalPrograms });
    } else {
      this.loadPrograms();
    }
  
    if (this.state.initialLoad) {
      this.fetchEndCursor();
    }
 
    window.addEventListener('popstate', this.handlePopState);
    window.history.replaceState(
      {
        content: `Page=1`,  // Using template literal for dynamic string
        id: 1,  // Example photo, you can adjust as needed
      },
      document.title,
      window.location.pathname
    );
    this.setState({ previousPage: 0 });

    console.log("Effects Dump: componentDidMount", window.history.state, this.state.prevPage);
  }
  
  componentWillUnmount() {
   window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState = (event) => {
    if (event.state) {
      this.setState({ currentCat: event.state });
    }

   console.log("Effects Dump: handlePopState AFTER setState", window.history.state.id, this.state.currentPage);
      if (this.state.currentPage>window.history.state.id)

   this.handlePrevious();
   else 

    this.handleNext();
  };
  

  
  handleNext = () => {
    this.setState(
      prevState => ({
        currentPage: prevState.currentPage + 1,
        startCursor: null,
        updateCounts: false,
      }),
      () => {
        this.loadPrograms();
      }
    );
  };
  
  handlePrevious = () => {
    this.setState(
      prevState => ({
        currentPage: prevState.currentPage - 1,
        endCursor: null,
        updateCounts: false,
      }),
      () => {
        this.loadPrograms();
      }
    );
  };
  



  loadPrograms = () => {

    console.log("Effects Dump : localPrograms", window.history.state, this.state.currentPage)
    const scrollToTop = () => {
      if (window.scrollY > 200) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
      }
  };


  scrollToTop();

    const { endCursor, startCursor, selectedCountries, selectedDegreeTypes, selectedStudyAreas, searchTerm,  sortBy } = this.state;
  
  

    const filters = [];
    if (selectedCountries.length > 0) {
      filters.push(`country: [${selectedCountries.map(c => `"${c}"`).join(",")}]`);
    }
    if (selectedDegreeTypes.length > 0) {
      filters.push(`degreeType: [${selectedDegreeTypes.map(d => `"${d}"`).join(",")}]`);
    }
    if (selectedStudyAreas.length > 0) {
      filters.push(`studyArea: [${selectedStudyAreas.map(s => `"${s}"`).join(",")}]`);
    }
    if (searchTerm) {
      filters.push(`search: "${searchTerm}"`);
    }
    const filterQuery = filters.length > 0 ? `${filters.join(", ")}` : '';
  
  

    let limitQuery;

    if (endCursor) {
      limitQuery = `first: 12`;
    } else if (startCursor) {
      limitQuery = `last: 12`;
    } else {
      limitQuery = `first: 12`; 
    }
    
    const paginationQuery = `
      ${endCursor ? `, after: "${endCursor}"` : ''}
      ${startCursor ? `, before: "${startCursor}"` : ''}
    `;

    const sortQuery = sortBy
    ? `orderby: { field: TITLE, order: ${sortBy === "title-asc" ? "ASC" : "DESC"} }`
    : `orderby: { field: MENU_ORDER, order: ASC}`;



  

  /*  console.log("Effects Dump - Pagination Executing query:", this.state.initialLoad, this.state.endCursor );
   if (this.state.initialLoad && !this.state.endCursor) {
      limitQuery = `first: 24`;  
    } 
*/
    this.setState({
      initialLoad: false
    });

    
   
    
    const query = `
      {
        allProgramList(
          ${limitQuery}
          ${paginationQuery}
          where: { ${filterQuery} ${sortQuery} }
        ) {
          edges {
            node {
              id
              title
              slug
              databaseId
              programList {
                universityRanking
                programDudation

                shortEligibility
                youSave
                term1
                term2
                year2And3
                featured
                featuredOrder
                degreeType
                directEligibilityShort
                country
                pgDetailForListing
                term1Title
                term2Title
                term3Title
                selectUniversity {
       
            ... on University {
              id
              slug
              title
            }
          } 
              }
              universityImage {
                thirdPartyLink
                universityLogo {
                  sourceUrl
                }
              }
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
            total
  uniTotal
          }
        }
      }
    `;
  

   

   

    axios.post('https://assets.theworldgrad.com/graphql', { query })
  .then(response => {
    //console.log("Effects Dump - Pagination Executing query:",query );
    //console.log("Effects Dump - Pagination Executing query:", paginationQuery );
    //console.log("Effects Dump - Pagination Executing query:", response.data.data.allProgramList.pageInfo);
    //console.log('Effects Dump - this.state.updateCounts->'+this.state.updateCounts+"--"+pageInfo.total+"--"+pageInfo.uniTotal);
    const { edges, pageInfo } = response.data.data.allProgramList;

 
    if (this.state.updateCounts) {
      this.setState({
        totalPrograms: pageInfo.total,
        uniTotal: pageInfo.uniTotal,
      });
    }
    
    const totalItems = this.state.totalPrograms;
    const itemsPerPage = 12;
    const currentPage = this.state.currentPage;

    this.setState({
      displayedPrograms: edges.map(edge => edge.node),
      endCursor: pageInfo.endCursor,
      startCursor: pageInfo.startCursor,
      hasNextPage: currentPage * itemsPerPage < totalItems,
      hasPreviousPage: currentPage > 1,
      loading: false
    });
    

  })
  .catch(error => {
    console.error("Error fetching data:", error);
    this.setState({ loading: false }); 
  });

this.setState({ loading: true });
  }
  




  handleCheckboxChange = (e, type) => {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(prevState => {
      let updatedList;
      if (type === 'countries') {
        updatedList = checked
          ? [...prevState.selectedCountries, value]
          : prevState.selectedCountries.filter(item => item !== value);
      } else if (type === 'degreeTypes') {
        updatedList = checked
          ? [...prevState.selectedDegreeTypes, value]
          : prevState.selectedDegreeTypes.filter(item => item !== value);
      } else {
        updatedList = checked
          ? [...prevState.selectedStudyAreas, value]
          : prevState.selectedStudyAreas.filter(item => item !== value);
      }

      return {
        [`selected${type.charAt(0).toUpperCase() + type.slice(1)}`]: updatedList,
        endCursor: null,
        startCursor: null,
        currentPage: 1,
        updateCounts: true,
      };
    }, this.loadPrograms);
  }

  handleSearchSubmit = (event) => {


    event.preventDefault(); 

    const searchQuery = document.getElementById('lname').value; 

    this.setState({
      searchTerm: searchQuery,
      endCursor: null,
      startCursor: null,
      currentPage: 1,
      updateCounts: true,
    }, this.loadPrograms); 
  };

  handleKey = (key) => {
    //console.log("Effects Dump - Pagination Executing query:", key );



    const searchQuery = key; 

    this.setState({
      searchTerm: searchQuery,
      endCursor: null,
      startCursor: null,
      currentPage: 1,
      updateCounts: true,
    }, this.loadPrograms); 
  };



  handleSortChange = (e) => {
    this.setState({
      sortBy: e.target.value,
      endCursor: null,
      startCursor: null,
      currentPage: 1,
      updateCounts: false,
    }, this.loadPrograms);
  }

  openMobileFiler = (e) => {
    const mobileFIlterContent = document.getElementById('fiter-course-finder');
    mobileFIlterContent.classList.add("open");
	const mobileFIlterButton = document.getElementById('filter-button-parent');
    mobileFIlterButton.classList.add("open");

};

closeMobileFiler = (e) => {

  const mobileFIlterContent2 = document.getElementById('fiter-course-finder');
  mobileFIlterContent2.classList.remove("open");
const mobileFIlterButton2 = document.getElementById('filter-button-parent');
  mobileFIlterButton2.classList.remove("open");
};

  render() {

    const { displayedPrograms, loading, hasNextPage, hasPreviousPage, currentPage, countries, degreeTypes, studyAreas, selectedCountries, selectedDegreeTypes, selectedStudyAreas, searchTerm, totalPrograms, uniTotal } = this.state;
  
		const post = this.props.data.allWordpressPage.nodes[0];
    const initialPrograms = this.props.data.allWordpressWpPogramlist.edges;

		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 


    return (
      <Layout>
               <Helmet> 
            <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
</Helmet>

            <PopupForm time="900000000" />

      <div className="newStyle">
    

      <div className="container">
					<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
						<li class="breadcrumb-item">
							<a href="/">Home </a>
						</li>
						<li class="breadcrumb-item active"><a target="_blank">Degree Finder</a></li>
					</ol>
				</div>
      
        <section 
          className="wow animate__fadeIn bg-light-gray padding-125px-tb sm-padding-80px-tb position-relative" 
          style={{
            backgroundImage: "url(https://assets.theworldgrad.com/gatsby-assets/images/Course-finder-banner-NewDesktop.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "top"
          }}>
          <div className="opacity-medium-2 bg-medium-slate-blue"></div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-lg-7 col-md-8 position-relative text-center d-flex justify-content-center flex-column">
                <h1 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                  Choose The Right Degree For You
                </h1>
              </div>
            </div>
          </div>
        </section>
    
   
        <section className="half-section blog-left-side-bar position-relative overflow-visible pb-0">
        <div className="course-search-box-holder">
          <form className="course-search-box" onSubmit={this.handleSearchSubmit}>
            <input
              type="text"
              id="lname"
              name="lname"
              className="programSortBySalect programvalues"
              placeholder="Search for a degree or university"
              value={this.state.searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
            />
            <input type="submit" value="" />
          </form>
        </div>
      </section>
    
      

        <div id="program-listing-container" className="container">
          <div className="row justify-content-center">
      
            <aside id="fiter-course-finder" className="col-12 col-xl-3 col-lg-3 col-md-7 lg-padding-4-rem-right md-padding-15px-right">
              <span className="alt-font font-weight-500 text-medium position-relative fiter-course-finder-title margin-25px-bottom text-extra-dark-gray d-block margin-25px-bottom">
                <strong>Filter results by</strong>
                <span className="close filter-icon-close" onClick={(e) => this.closeMobileFiler(e)}></span>
              </span>
    
              <div id="programSelector" className="d-inline-block w-100 margin-30px-bottom border-bottom border-color-medium-gray">
                <span className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-25px-bottom">Program level</span>
                <ul className="list-style-07 filter-category margin-30px-bottom">
                  {degreeTypes.map(degreeType => (
                    <li key={degreeType}>
                      <input 
                        type="checkbox" 
                        value={degreeType}
                        checked={selectedDegreeTypes.includes(degreeType)}
                        onChange={e => this.handleCheckboxChange(e, 'degreeTypes')}
                      />
                      <span>{degreeType}</span>
                    </li>
                  ))}
                </ul>
              </div>
    
              <div id="countrySelector" className="d-inline-block w-100 margin-30px-bottom border-bottom border-color-medium-gray">
                <span className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-25px-bottom">Country</span>
                <ul className="list-style-07 margin-30px-bottom">
                  {countries.map(country => (
                    <li className="filter-category-parent" key={country}>
                      <input 
                        type="checkbox" 
                        value={country}
                        checked={selectedCountries.includes(country)}
                        onChange={e => this.handleCheckboxChange(e, 'countries')}
                      />
                      <span>{country}</span>
                    </li>
                  ))}
                </ul>
              </div>
    
              <div id="disciplineSelector" className="d-inline-block w-100 margin-30px-bottom border-bottom border-color-medium-gray">
                <span className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-25px-bottom">Discipline</span>
                <ul className="list-style-07 filter-category margin-30px-bottom">
                  {studyAreas.map(studyArea => (
                    <li className="filter-category-parent" key={studyArea}>
                      <input 
                        type="checkbox" 
                        value={studyArea}
                        checked={selectedStudyAreas.includes(studyArea)}
                        onChange={e => this.handleCheckboxChange(e, 'studyAreas')}
                      />
                      <span>{studyArea}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </aside>
    <div id="filter-button-parent">
<a data-animation="animated zoomInUp" id="filter-done-btn" class="filter-done-btn btn btn-theme btn-large effect mt-50" onClick={(e) => this.closeMobileFiler(e)} href="#">DONE</a>
</div>
          
            <div className="program-grid col-lg-9 program-count-wrapper result-course-finder ">
            <div className="row justify-content-center">
            <div className="col-6">
            <a href="#" class="filter-icon-mobile" onClick={(e) => this.openMobileFiler(e)}><img src="https://assets.theworldgrad.com/gatsby-assets/images/icon-filter.svg" alt="||" /> Filter</a>
            <h4 className="program-count-desktop program-count">
  {loading 
    ? "Loading..." 
    : `${totalPrograms} ${totalPrograms === 1 ? "degree" : "degrees"} at ${uniTotal} ${uniTotal === 1 ? "university" : "universities"}`}
</h4>



   
                </div>

        <div className="col-6">
              <div id="product-sort-box" className="blog-filter-holder">
                <select className="programSortBySalect" onChange={this.handleSortChange}>
                  <option value="title-asc" className="programvalues">
                    Sort By
                  </option>
                  <option value="title-asc" className="programvalues">
                    Course Title A-Z
                  </option>
                  <option value="title-desc" className="programvalues">
                    Course Title Z-A
                  </option>
                </select>
                <div className="clearfix"></div>
                </div>
                </div>
                </div>
              <div className="row justify-content-center">
        
              {initialPrograms.length > 0 && this.state.initialLoad && !loading && (
                  initialPrograms.map((program) => (
                    <div  key={program.node.databaseId} className="col-sm-6 col-12 col-lg-4 program-box-wrapper margin-30px-bottom">
                    <ProgramInfoCommon
     DetailPageUrl={
      program.node.third_party_link
        ? program.node.third_party_link
        : `/program-detail/${program.node.acf.country.replace(/\s+/g, "-").toLowerCase()}/${program.node.acf.select_university.post_name}/${
            program.node.acf.degree_type === "Postgraduate" ? "pg" : "ug"
          }/${_.kebabCase(program.node.slug)}/`
    }
    
      Target={program.node.third_party_link ? `_blank`: `_self`}
      UniversityImage={ program.node.featured_media.source_url}

     UniversityRank = {`${program.node.acf.university_ranking}`}

      UniversityLogo={program.node.acf.university_logo.source_url}
      ProgramName={program.node.title}
      PogramDuration={program.node.acf.program_dudation}
      Country={program.node.acf.country.trim()}
      UniversityName={program.node.acf.select_university.post_title}
      ShortEligibility={program.node.acf.short_eligibility}
      YouSave={program.node.acf.you_save}
      Term1Info={program.node.acf.term_1}
      Term2Info={program.node.acf.term_2}
      Term3Info={program.node.acf.year_2_and_3}
      Term1Title={program.node.acf.term_1_title}
      Term2Title={program.node.acf.term_2_title}
      Term3Title={program.node.acf.term_3_title}
      isFeatured={program.node.acf.featured}
      featuredOrder={program.node.acf.featured_order}
      wordpress_id={program.node.wordpressid}
    />
      

                  </div>
                  ))
                )}

              {loading && (
   <>
   {Array(12).fill(null).map((_, index) => (
     <div key={index} className="col-sm-6 col-12 col-lg-4 program-box-wrapper margin-30px-bottom dummy-parent">
       <div className="blog-post-content wow pb-60 fadeInUp br-15 h-100 blog-text overflow-hidden dummy" data-wow-duration="1s" data-wow-delay=".1s"><div class="loading-circle"></div></div>
     </div>
   ))}
 </>
)}
                {displayedPrograms.length === 0 && !loading && !this.state.initialLoad && (
                  <p>No programs available</p>
                )}
                {displayedPrograms.length > 0 && !loading && (
                  displayedPrograms.map((program) => (
                  
                    <div  key={program.databaseId} className="col-sm-6 col-12 col-lg-4 program-box-wrapper margin-30px-bottom">
                   
                      <ProgramInfoCommon
                 DetailPageUrl={
                  program.universityImage.thirdPartyLink
                    ? program.universityImage.thirdPartyLink
                    : `/program-detail/${program.programList.country.replace(/\s+/g, "-").toLowerCase()}/${program.programList.selectUniversity.slug}/${
                        program.programList.degreeType === "Postgraduate" ? "pg" : "ug"
                      }/${_.kebabCase(program.slug)}/`
                }
                
                        Target={program.universityImage.thirdPartyLink ? `_blank`: `_self`}
                        UniversityImage={program.featuredImage?.node.sourceUrl}
                        UniversityRank={program.programList.universityRanking}
                        UniversityLogo={program.universityImage.universityLogo.sourceUrl}
                        ProgramName={program.title}
                        PogramDuration={program.programList.programDudation}
                        Country={program.programList.country}
                        UniversityName={program.programList.selectUniversity.title}
                        ShortEligibility={program.programList.shortEligibility}
                        YouSave={program.programList.youSave}
                        Term1Info={program.programList.term1}
                        Term2Info={program.programList.term2}
                        Term3Info={program.programList.term3}
                        Term1Title={program.programList.term1Title}
                        Term2Title={program.programList.term2Title}
                        Term3Title={program.programList.term3Title}
                        isFeatured={program.programList.featured}
                        featuredOrder={program.programList.featuredOrder}
                        wordpress_id={program.databaseId}
                      />
                    </div>
                  ))
                )}
{(hasPreviousPage || hasNextPage) && (
  <div className={`pagination-box col-12 d-flex justify-content-between`}>
    {hasPreviousPage && currentPage > 1 && (
      <a onClick={(e) => this.handleLinkClick(e, currentPage-1, "previous")} className="pagination-link prev-page-link">
        <img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black" />
        Go to Page <span>{currentPage - 1}</span>
      </a>
    )}
    {hasNextPage && (
      <a onClick={(e) => this.handleLinkClick(e, currentPage+1, "next")} className="pagination-link next-page-link ml-auto">
        Go to Page <span>{currentPage + 1}</span>
        <img src={ArrowRightSolidIcon} alt="->" className="right-icon newicon newicon-black left-5px" />
      </a>
    )}
  </div>
)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    
    );
  }
}

export default ProgramListingArchive;

export const ProgramListing = graphql`
  query ProgramListing{
    allWordpressPage(filter: {wordpress_id: {in: 4929}}) {
      nodes {
        title
        wordpress_id
        acf {
          meta_title
          meta_description
          focus_keyphrase
          canonical_url
          dynamic_schema
        }
      }
    }
  allWordpressWpPogramlist(filter: {}, sort: {fields: menu_order, order: ASC}, limit: 12) {
    edges {
      node {
        title
        wordpress_id
        slug
        menu_order
        featured_media {
          source_url
        }
        acf {
          country
          university_ranking
          university_logo {
            source_url
          }
          third_party_link
          degree_type
          study_area
          featured
          featured_order
         select_university {
post_title
            post_name
        
          }
          program_dudation
        
       
          short_eligibility
          you_save
          term_1
          term_2
          year_2_and_3
          term_1_title
          term_2_title
          term_3_title
        }
      }
    }
  }
}

`;