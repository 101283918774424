import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EventRedirect = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        // Fetch the most recent event
        const response = await axios.post('https://assets.theworldgrad.com/graphql', {
          query: `
            query {
              events(first: 1) {
                edges {
                  node {
                    slug
                  }
                }
              }
            }
          `,
        });

        // Handle response
        const recentEventData = response.data.data?.events?.edges;
        if (!recentEventData || recentEventData.length === 0) {
          console.error("No events found.");
          setLoading(false);
          return;
        }

        const eventSlug = recentEventData[0].node.slug;

        // Redirect to the event URL
        if (eventSlug) {
          window.location.href = `/event-detail/${eventSlug}`;
        } else {
          console.error("Event slug is missing.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching the event:", error);
        setLoading(false);
      }
    };

    fetchEventData();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading event, please wait...</p>
      ) : (
        <p>No event found or an error occurred.</p>
      )}
    </div>
  );
};

export default EventRedirect;
