import React, { Component } from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

import ReactHtmlParser from 'react-html-parser';
import "../css/swiper-bundle.min.css";

import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';


import PopupForm from "../components/popupForm";
import PopupVideo from "../components/popupVideo";
import SimpleSlider from "../components/homepage-banner-slider";
import TestimonialSlider from "../components/testimonial-slider";

import { graphql } from "gatsby";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';
import CalendarDaysRegularIcon from '../images/icons/calendar-days-regular.svg';

import WhatsNew from '../components/whats-new';

import InstagramReels from '../components/instagram-reels';


Swiper.use([EffectCoverflow, Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Home extends Component {

  constructor(props) {
    super(props)
    this.marqueeContainers = []
    this.containerRef = React.createRef()
    this.state = {
      HomeData: this.props.data.allWordpressPage.nodes,
      uniData: this.props.data.allWordpressWpUniversities.nodes,
      HomeTestmonials: this.sortTestimonialsForDesktop(this.props.data.allWordpressWpTestimonial.edges),
      HomeTestmonialsMobile: this.sortTestimonialsForMobile(this.props.data.allWordpressWpTestimonial.edges),
      HomeNews: this.props.data.allWordpressWpNews.edges,
      animationDuration: 10,
      isDesktop:  true, 
      time: 90000000
    }
  }
  sortTestimonialsForDesktop(testimonials) {
    return testimonials.slice().sort((a, b) => {
      const sequencA = a.node.acf.desktop_sequencing;
      const sequencB = b.node.acf.desktop_sequencing;
      return sequencA - sequencB;
    });
  }
  sortTestimonialsForMobile(testimonials) {
    return testimonials.slice().sort((a, b) => {
      const sequencA = a.node.acf.mobile_sequencing;
      const sequencB = b.node.acf.mobile_sequencing;
      return sequencA - sequencB;
    });
  }
  
  componentDidMount() {

    this.initializeMarquee();


   let swiperSlides;
   if (window.innerWidth > 768) { 
    swiperSlides = document.querySelectorAll('.home-testimonials-box-new .swiper-slide.mobile');
  } else {
    swiperSlides = document.querySelectorAll('.home-testimonials-box-new .swiper-slide.desktop');
  }
  
  swiperSlides.forEach((slide) => {
    slide.remove();
  });

    document.getElementById('speak-to-an-expert').onclick = function () {
      document.getElementById('book-an-appointment').click();
    }

    var swiper = new Swiper('.swiper-testimonial', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      loop: true,
      slidesPerView: "1",
      coverflowEffect: {
        rotate: 0,
        stretch: 100,
        depth: 150,
        modifier: 1.5,
        slideShadows: true,
      },
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination-testimonial',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-testimonial',
        prevEl: '.swiper-prev-testimonial',
      },
      breakpoints: { 768: { slidesPerView: 2 } }
    });



    var swiper = new Swiper('.swiper-university', {
      slidesPerView: 1,
      spaceBetween: 50,
      slidesPerGroup: 1,
      loop: true,
      loopFillGroupWithBlank: false,

      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination-university',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-university',
        prevEl: '.swiper-prev-university',
      },
      breakpoints: {
        1200: { slidesPerGroup: 4, slidesPerView: 4 },
        992: { slidesPerGroup: 3, slidesPerView: 3 },
        768: { slidesPerGroup: 2, slidesPerView: 2 }
      }

    });



    var swiper = new Swiper('.swiper-news', {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: false,
      loopFillGroupWithBlank: false,

      autoplay: {
        delay: 700000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination-news',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-news',
        prevEl: '.swiper-prev-news',
      },
      breakpoints: {
        1200: { slidesPerGroup: 3, slidesPerView: 3 },
        992: { slidesPerGroup: 2, slidesPerView: 2 },
        768: { slidesPerGroup: 1, slidesPerView: 1 }
      }
    });

    var swiper = new Swiper('.swiper-main', {
      slidesPerView: 1,

      loop: true,
      nav: true,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination-main',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-main',
        prevEl: '.swiper-prev-main',
      },
    });


    var swiper = new Swiper('.lp-testimonials-box', {
            centeredSlides: false,
            loop: false,
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerGroup: 1,
            autoplay: {
              delay: 7000,
              disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination-testimonial',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-next-testimonial',
              prevEl: '.swiper-prev-testimonial',
            },
            
            breakpoints: {
              1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20},
              991: { slidesPerGroup: 2, slidesPerView: 2},
              768: { slidesPerGroup: 1, slidesPerView: 1 }
            }
          });
    
      var swiper = new Swiper('.instagram-reels', {
              centeredSlides: false,
              loop: false,
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              autoplay: {
                delay: 7000,
                disableOnInteraction: false
              },
              pagination: {
                el: '.swiper-reels-pagination',
                clickable: true,
              },
              navigation: {
                nextEl: '.swiper-reels-next',
                prevEl: '.swiper-reels-previous',
              },
              
              breakpoints: {
                1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20},
                991: { slidesPerGroup: 2, slidesPerView: 2},
                768: { slidesPerGroup: 1, slidesPerView: 1 }
              }
            });

      



    const params = new URLSearchParams(window.location.search);


    if (params.has('utm') && params.get('utm') === 'THE') {

      const navbarDiv = document.getElementById('navbarSupportedContent');
      if (navbarDiv) {
        navbarDiv.style.display = 'none';
      }

      const footer = document.querySelector('footer');
      if (footer) {
        footer.style.display = 'none';
      }


      const mobile_menu = document.querySelector('.navbar-toggle');
      if (mobile_menu) {
        mobile_menu.style.display = 'none';
      }
    }



      
  
      var footUniHolder = document.getElementById("footUniHolder");

      if (footUniHolder) {
        footUniHolder.remove();
      }
   

  }


  getObjectWidth = (obj) => {
    if (obj.offsetWidth) return obj.offsetWidth;
    if (obj.clip) return obj.clip.width;
    return 0;
  };

  initializeMarquee = () => {
    this.createMarqueeContainer("latest-news");
    this.rotateMarquee(this.marqueeContainers);
  };

  createMarqueeContainer = (id) => {
    const container = this.containerRef.current;
    const lastTick = container.getElementsByTagName("span").length;
    const itemWidth = this.getObjectWidth(container.getElementsByTagName("p")[0]) *1.125
   // console.log("Effects Dunp 3", itemWidth, lastTick);
   // console.log("Effects Dump 4", this.getObjectWidth(document.getElementById('latest-news')));

    //console.log("Effects Dump 5", document.getElementsByClassName('marquee')[0]);

    const fullWidth = this.getObjectWidth(container);
    const textContent = container.getElementsByTagName("p")[0].innerHTML;
    const height = container.style.height;

    container.innerHTML = ""; // Clear previous content

    container.onmouseout = () => this.rotateMarquee(this.marqueeContainers);
    container.onmouseover = () => cancelAnimationFrame(this.marqueeContainers[0]?.animationID);

    container.items = [];
    const maxItems = Math.ceil(fullWidth / itemWidth) + 1;

    for (let i = 0; i < maxItems; i++) {
      container.items[i] = document.createElement("div");
      container.items[i].innerHTML = textContent;
      container.items[i].style.position = "absolute";
      container.items[i].style.left = itemWidth * i + "px";
      container.items[i].style.width = itemWidth + "px";
      container.items[i].style.height = height;
      container.appendChild(container.items[i]);
    }

    this.marqueeContainers.push(container);
  };

  rotateMarquee = (containers) => {

    if (!containers) return;
  
    for (let j = containers.length - 1; j > -1; j--) {
      const maxItems = containers[j].items.length;
      const container = containers[j];

      for (let i = 0; i < maxItems; i++) {
        const itemStyle = container.items[i].style;
        itemStyle.left = parseInt(itemStyle.left, 10) - 1 + "px";
      
      }
  
      const firstItemStyle = container.items[0].style;
      const lastItemStyle = container.items[container.items.length - 1].style;

      if (parseInt(firstItemStyle.left, 10) + parseInt(firstItemStyle.width, 10) < 0) {
        const shiftedItem = container.items.shift();
        const lastItemRight = parseInt(lastItemStyle.left, 10) + parseInt(lastItemStyle.width, 10);
  
        shiftedItem.style.left = lastItemRight + "px";
        
        container.items.push(shiftedItem);
      }
    }
  
    containers[0].animationID = requestAnimationFrame(() => this.rotateMarquee(containers));
  };

  handlePopupWindow = () => {
    this.setState({ time: Math.random() })
  }

  homeOurUniPartLink = (e) => {
    const utabHead = e.target.parentNode;

    if (utabHead) {
      const universityPartnersSec = document.querySelector('.home-university-partners-sec');
      if (utabHead.classList.contains('active')) {
        utabHead.classList.remove('active');
        universityPartnersSec.classList.remove('active');
        document.querySelector('.view-more-universities-btn').textContent =  document.querySelector('.view-more-universities-btn').textContent.replace('Less', 'More').replace('-', '+');

      } else {
        console.log("Effects Dump ->", utabHead.classList.contains('active') );
        utabHead.classList.add('active');
        universityPartnersSec.classList.add('active');
        document.querySelector('.view-more-universities-btn').textContent = document.querySelector('.view-more-universities-btn').textContent.replace('More', 'Less').replace('+', '-');;

      }
    }
    
  };

  countryDropdownLink = (e) => {
    const clickedElement = e.target.parentNode;
    const allCountryFilters = document.querySelectorAll('.country-filter');

    if (clickedElement && allCountryFilters) {
  
        const isAlreadyActive = clickedElement.classList.contains('active');


        allCountryFilters.forEach((filter) => {
            filter.classList.remove('active');
        });

     
        if (!isAlreadyActive) {
            clickedElement.classList.add('active');
        }
    }

};



  render() {



    const home_slider = this.state.HomeData[0].acf.slider;


    const post = this.state.HomeData[0];
  
    const metatitle = post.acf.meta_title;
    const metadescription = post.acf.meta_description;
    const canonical_url =`https://theworldgrad.com${this.props.path}`;
    const focus_keyphrase = post.acf.focus_keyword;


    const university_logo_slider_all = this.state.uniData;
    const featuredIds = post.acf.featured_universities_ids
    .split(',')
    .map(id => id.trim()) 
    
    .filter(id => id !== '')
    
    .map(id => parseInt(id)); 
    


const university_logo_slider = featuredIds.map(id => 
  university_logo_slider_all.find(uni => uni.wordpress_id === id)
).filter(uni => uni !== undefined); 

  //console.log("Efefcts Dump ->", featuredIds); 

    const process_steps = this.state.HomeData[0].acf.process_steps;

    const testimonial_heading = this.state.HomeData[0].acf.testimonial_heading;


    const awards_and_recognitions_title = this.state.HomeData[0].acf.awards_and_recognitions_title;
    const awards_and_recognitions = this.state.HomeData[0].acf.awards_and_recognitions;

    const whats_new_title = this.state.HomeData[0].acf.whats_new_title;

    const HomeTestmonials = this.state.HomeTestmonials;
    const HomeTestmonialsMobile = this.state.HomeTestmonialsMobile;

    const filteredTestimonials = HomeTestmonials.filter(
      ({ node }) => node.acf.small_image && node.acf.small_image.source_url
    );

    const our_track_record_title = this.state.HomeData[0].acf.our_track_record_title;
    const students_educated_count = this.state.HomeData[0].acf.students_educated_count;
    const students_educated_text = this.state.HomeData[0].acf.students_educated_text;
    const satisfaction_rating_text = this.state.HomeData[0].acf.satisfaction_rating_text;

    const visa_success_rate_text = this.state.HomeData[0].acf.visa_success_rate_text;
    const overseas_degrees_count = this.state.HomeData[0].acf.overseas_degrees_count;
    const overseas_degrees_text = this.state.HomeData[0].acf.overseas_degrees_text;
    const tution_fees_saved_number = this.state.HomeData[0].acf.tution_fees_saved_number;
    const tution_fees_saved_text = this.state.HomeData[0].acf.tution_fees_saved_text;
 

    const our_smart_program_title = this.state.HomeData[0].acf.our_smart_program_title;
    const our_smart_program_detail = this.state.HomeData[0].acf.our_smart_program_detail;
    const our_smart_program_image = this.state.HomeData[0].acf.our_smart_program_image;
    const our_smart_program_youtube_link = this.state.HomeData[0].acf.our_smart_program_youtube_link;


    const journey_process_title = this.state.HomeData[0].acf.journey_process_title;

    const instagram_section_title = this.state.HomeData[0].acf.instagram_section_title;
    const instagram_posts = this.state.HomeData[0].acf.instagram_posts;
    const instagram_link = this.state.HomeData[0].acf.instagram_link;

    const page_title = this.state.HomeData[0].acf.page_title;

    const our_track_record_footer = this.state.HomeData[0].acf.our_track_record_footer;
    const our_smart_program_button_text = this.state.HomeData[0].acf.our_smart_program_button_text;
    const our_smart_program_button_link = this.state.HomeData[0].acf.our_smart_program_button_link;
    const our_university_partners_title = this.state.HomeData[0].acf.our_university_partners_title;
    const our_university_partners_footer = this.state.HomeData[0].acf.our_university_partners_footer;
    
    const  whatsNewData = this.state.HomeData[0].acf.whats_new_blocks;


    let review_rating_count = 0
    let count = 0
    for (let i = 0; i < HomeTestmonials.length; i++) {
      count = parseFloat(HomeTestmonials[i].node.acf.review_rating);
      review_rating_count = review_rating_count + count;
    }
    let avg = review_rating_count / HomeTestmonials.length;

    const activeStars = avg.toFixed(1);
    


    
    const dynamic_schema =   post.acf.dynamic_schema.replace(/\\/g, ''); 

    const additional_schema_1 =   post.acf.additional_schema_1; 
    const additional_schema_2 =   post.acf.additional_schema_2; 
    const additional_schema_3 =   post.acf.additional_schema_3; 
    const additional_schema_4 =   post.acf.additional_schema_4; 



    return (
      <div>
        <PopupForm time={this.state.time} />


        <Layout>
  <Helmet>
    <title>{metatitle}</title>
    <meta name="description" content={metadescription} />
    {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
    <link rel="canonical" href={canonical_url} /> 
    {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
    {(() => {if(post.acf.additional_schema_1 != '' && post.acf.additional_schema_1 != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.additional_schema_1))}</script> )} })()}  
    {(() => {if(post.acf.additional_schema_2 != '' && post.acf.additional_schema_2 != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.additional_schema_2))}</script> )} })()}  
    {(() => {if(post.acf.additional_schema_3 != '' && post.acf.additional_schema_3 != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.additional_schema_3))}</script> )} })()}  
    {(() => {if(post.acf.additional_schema_4 != '' && post.acf.additional_schema_4 != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.additional_schema_4))}</script> )} })()}      

    
  </Helmet>


          <div class="newStyle homepage">
          {/*  <div id="home-page-band" class="top-bar-style-two top-message font-weight-500 text-center text-white"><span>{page_title}</span>
            
     
</div> */}



            <section class=" heroarea  banner-sec home-banner-styling py-0 f7 banner-area  banner-style-six bg-cover">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 p-0">
                    <div class="swiper-container swiper-main" >
                      <SimpleSlider hero_slider={home_slider} />
                    </div>
                    <div class="swiper-pagination swiper-pagination-main swiper-pagination-new"></div>
                   
                  </div>

                </div>
              </div>
            </section>
            
  
            <div id="home-page-band" className="top-bar-style-two top-message font-weight-500 text-center text-white"> 
            <div
  id="latest-news"
  className="marquee text-white"
  ref={this.containerRef}
>
  <p style={{ whiteSpace: "nowrap" }}>
  {post.acf.news.map((newsItem, index) => (
      <React.Fragment key={index}>
         <span className="yellowbullet"></span>
        {newsItem.news_item}
       
      </React.Fragment>
    ))}

  </p>
</div> 
              </div>

      
            <div id="connect-with-us-mobile" class="text-center d-none">
              <a data-animation="animated zoomInUp" class="btn btn-theme-rev effect btn-md  modal-popup" id="speak-to-an-expert" href="#get-in-touch"> <img src={CalendarDaysRegularIcon} alt="Icon" className="left-icon newicon" /> CONNECT WITH US</a>
            </div>
       <section class="half-section  ">
              <div class="container">
                <div class="row">
                  <div class="col-md-10 offset-md-1 text-center margin-two-bottom">
                  <h2 class="color-blue">{whats_new_title}</h2>
                  </div>
                </div>

                <div class="row">

 <WhatsNew whatsNewData={whatsNewData} />

 </div>
              </div>
            </section>

            <div class="divider"></div>
         



                          <section className="padding-50px-tb bg-white  half-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center col-lg-12 col-sm-12 margin-15px-bottom">
            <h2 className="color-blue">{testimonial_heading}</h2>
          </div>
        </div>
        <div className="home-university">
          <div className="row">
            <div className="col-md-12">
            <TestimonialSlider testimonials={filteredTestimonials} />
            </div>
          </div>
        </div>
      </div>
    </section>

                       
{/*

            <section class="half-section  wow animate__fadeIn">
              <div class="container">
                <div class="row">
                  <div class="col-md-10 offset-md-1 text-center margin-two-bottom">
                    <h2 class="color-blue">{testimonial_heading}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="swiper-container swiper-style-new-design lp-testimonials-box home-testimonials-box-new">
                      <div className="swiper-wrapper">

{HomeTestmonials.map((testmonial) => (
                          <div class="swiper-slide desktop">
                            <div class="lp-testimonial-box w-100 h-100">
                              <div class="lp-scholership"> 
                              {testmonial.node.acf.scholership_image && (
  <img
    src={testmonial.node.acf.scholership_image.source_url}
    alt={testmonial.node.title}
    className="lp-testimonial-scholership-img"
  />
)}
                              </div>
                              <div class="lp-testimonial-head">
                                
                                    
                                <img src={testmonial.node.featured_media.source_url} alt={testmonial.node.title} class="lp-testimonial-head-img" /><br/>
           
                                <div class="lp-testimonial-head-detail">
                                  <p class="mb-0 font-weight-500">{testmonial.node.title}</p><p class="font-weight-500 mb-2">{testmonial.node.acf.state}</p>
                                  <p class="mb-0">{testmonial.node.acf.board_and_percentage_obtained}</p>
                                  <p class="mb-0">Batch - {testmonial.node.acf.batch_of_year}</p>
                                  <p class="mb-0">{testmonial.node.acf.program_name}</p>
                                </div>
                               
                              </div>

                              <img src={testmonial.node.acf.country_flag?.source_url} alt="Flag" class="lp-testimonial-qoute" />
                              <div class="lp-testimonial-detail">
                              
                                {ReactHtmlParser(testmonial.node.content)}
                                
                              </div>
                              
                              {testmonial.node.acf.youtube_video_link && testmonial.node.acf.youtube_video_link !== '#' && (
                                  <div class="lp-testimonial-date">
                                    <PopupVideo time={this.state.time} videoURL={testmonial.node.acf.youtube_video_link} designType="New" />
                                    
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}

{HomeTestmonialsMobile.map((testmonial) => (
                          <div class="swiper-slide mobile">
                            <div class="lp-testimonial-box w-100 h-100">
                              <div class="lp-scholership "> 
                              {testmonial.node.acf.scholership_image && (
  <img
    src={testmonial.node.acf.scholership_image.source_url}
    alt={testmonial.node.title}
    className="lp-testimonial-scholership-img"
  />
)}

                              </div>
                              <div class="lp-testimonial-head">
                                
                                    
                                <img src={testmonial.node.featured_media.source_url} alt={testmonial.node.title} class="lp-testimonial-head-img" /><br/>
           
                                <div class="lp-testimonial-head-detail">
                                  <p class="mb-0 font-weight-500">{testmonial.node.title}</p><p class="font-weight-500 mb-2">{testmonial.node.acf.state}</p>
                                  
                                  <p class="mb-0">{testmonial.node.acf.board_and_percentage_obtained}</p>
                                  <p class="mb-0">Batch - {testmonial.node.acf.batch_of_year}</p>
                                  <p class="mb-0">{testmonial.node.acf.program_name}</p>
                                </div>
                               
                              </div>

                              <img src={testmonial.node.acf.country_flag?.source_url} alt={testmonial.node.title} class="lp-testimonial-qoute" />
                              <div class="lp-testimonial-detail">
                              
                                {ReactHtmlParser(testmonial.node.content)}
                                
                              </div>
                              
                              {testmonial.node.acf.youtube_video_link && testmonial.node.acf.youtube_video_link !== '#' && (
                                  <div class="lp-testimonial-date">
                                    <PopupVideo time={this.state.time} videoURL={testmonial.node.acf.youtube_video_link} designType="New" />
                                    
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}
                      </div>


                      <div className="swiper-pagination swiper-pagination-testimonial"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

*/}
            <div class="divider"></div>
            <section class="half-section our-track-record-new">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-12 text-center margin-two-bottom">
                    <h2 class="color-blue">{our_track_record_title}</h2>
                  </div>
                </div>
                <div class="row justify-content-center">
                <div class="col-6 col-md-3 col-sm-6 text-center   wow animate__fadeIn" data-wow-delay="0.2s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                      <h3 class="vertical-counter d-inline-flex alt-font appear font-weight-600 color-orng  mb-0 sm-letter-spacing-minus-1-half" data-to="{overseas_degrees_count}"><CountUp end={overseas_degrees_count} redraw={true} >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp></h3><span class="text-fast-blue title-extra-small color-orng font-weight-500 margin-5px-left">+</span>
                    </div>
                    <span class="alt-font d-block">{ReactHtmlParser(overseas_degrees_text)}</span>
                  </div>
                  <div class="col-6 col-md-3 col-sm-6 text-center sm-margin-30px-bottom wow animate__fadeIn" data-wow-delay="0.3s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                      <h3 class="vertical-counter d-inline-flex alt-font appear font-weight-600 color-blue mb-0 sm-letter-spacing-minus-1-half" data-to="{students_educated_count}"><CountUp end={students_educated_count} redraw={true} >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp></h3><span class="color-blue title-extra-small font-weight-500 margin-5px-left">+</span>
                    </div>
                    <span class="alt-font d-block">{ReactHtmlParser(students_educated_text)}</span>
                  </div>
                  <div class="col-6 col-md-3 col-sm-6 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                    <span class="text-fast-blue title-extra-small color-orng  margin-5px-left margin-5px-right font-weight-600 usd-million">USD</span>  <h3 class="d-inline-flex alt-font appear font-weight-600  color-orng mb-0 sm-letter-spacing-minus-1-half" data-to="{tution_fees_saved_number}"><CountUp end={tution_fees_saved_number} redraw={true} >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp></h3><span class="text-fast-blue title-extra-small color-orng  margin-5px-left font-weight-600 usd-million">Million</span>
                    </div>
                    <span class="alt-font d-block">{ReactHtmlParser(tution_fees_saved_text)}</span>
                  </div>

                  <div class="col-6 col-md-3 col-sm-6 text-center wow animate__fadeIn sm-margin-30px-bottom" data-wow-delay="0.6s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center mb-0">
                      <div class="homestar-rating">
  <span class="homestar"></span>
  <span class="homestar"></span>
  <span class="homestar"></span>
  <span class="homestar"></span>
  {parseInt(activeStars, 10) > 4  && <span className="homestar"></span>}
</div>
                    </div>
                    
    
                    <span class="alt-font d-block">{ReactHtmlParser(satisfaction_rating_text)}</span>
                  </div>
                  <div class="col-6 col-md-3 col-sm-6 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                    <div class="d-flex flex-row margin-10px-bottom justify-content-center">
                      <h3 class="vertical-counter d-inline-flex alt-font appear font-weight-600  color-orng mb-0 sm-letter-spacing-minus-1-half" data-to="{visa_success_rate_count}">2X</h3><span class="color-orng font-weight-500 margin-5px-left"></span>
                    </div>
                    <span class="alt-font d-block">{ReactHtmlParser(visa_success_rate_text)}</span>
                  </div>
                  

                  <div class="col-md-12 text-smallest margin-40px-top last-paragraph-no-margin sm-margin-25px-top  wow animate__fadeIn" data-wow-delay="0.8s">
                   {ReactHtmlParser(our_track_record_footer)}
                  </div>

                </div>
              </div>
            </section>

            <div class="divider"></div>
            <section class="half-section overflow-visible">
              <div class="container">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 p-0 margin-two-bottom text-center wow animate__slideInLeft" >
                    <h2 class="color-blue">{our_smart_program_title}</h2>
                  </div>
                </div>
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 col-xl-6 col-lg-6 col-md-10">
                    <div class="position-relative d-show-991 md-margin-30px-bottom w-15 w-80 MuiPaper-root ml-3 ml-auto mr-5 mr-auto">
                      <img src={our_smart_program_image.source_url} alt="TWG Smart Programs" class="br-15 " data-no-retina="" />
                      <div class="opacity-very-light bg-medium-slate-blue br-15 "></div>

                      <PopupVideo time={this.state.time} videoURL={our_smart_program_youtube_link} />
                    </div>

                    <div class="w-90 lg-w-80 sm-w-100 sm-text-center">
                      {ReactHtmlParser(our_smart_program_detail)}

                      <a href={our_smart_program_button_link} class="btn btn-theme-rev btn-theme-rev-orng effect btn-md modal-popup">{our_smart_program_button_text}<img src={ArrowRightSolidIcon} alt="->" className="newicon  left5pxtop4px " /></a>
                    
                    </div>

                  </div>
                  <div class="col-12 col-lg-6 position-relative md-margin-50px-bottom wow animate__slideInRight p-0 d-none-991" data-wow-delay="0.1s">
                    <img src={our_smart_program_image.source_url} alt="Our Smart Program" class="br-15 margin15 " data-no-retina="" />
                    <div class="opacity-very-light bg-medium-slate-blue br-15 margin15 "></div>

                    <PopupVideo time={this.state.time} videoURL={our_smart_program_youtube_link} />
                  </div>
                </div>
              </div>
            </section>

            <div class="divider"></div>
            <section class="half-section bg-white">
              <div class="container">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 p-0 margin-two-bottom text-center wow animate__slideInLeft" >
                    <h2 class="color-blue">{journey_process_title}</h2>
                  </div>
                  <div class="col-12 col-xl-12 col-lg-12 col-md-12">



                    <div class="col-12 p-0 process-step-style-home-page">

                      {process_steps.map((process, index) => (
                        <div class="process-step-style-02 wow animate__fadeIn" data-wow-delay="0.2s" >
                          <div class="process-step-item">
                            <div class="process-step-icon-wrap">
                              <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500">{index + 1}</div>
                              <span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
                            </div>
                            <div class="process-content last-paragraph-no-margin">
                              <span class="alt-font d-block font-weight-500 text-extra-dark-gray margin-5px-bottom">{process.process_step_title} </span>
                              <p class="w-80 xs-w-100 m-auto text-medium">{process.process_step_description}</p>
                            </div>
                          </div>
                        </div>
                      ))}



                    </div>
                  </div>

                </div>
              </div>
            </section>
            <div class="divider"></div>
            <section class="half-section bg-white home-university-partners-sec">
              <div class="container">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 padding-1-rem-lr margin-two-bottom text-center wow animate__slideInLeft" >
                    <h2 class="color-blue">{our_university_partners_title}</h2>
                  </div>
                </div>
   
                <div className="home-university">
  <div className="row">
    {university_logo_slider.slice(0, 6).map((image) => {
      const linkUrl = image.acf.university_detail_page?.includes("yes")
        ? `/university-detail/${image.slug}/`
        : image.acf.website_url || "";

      return (
        <div className="col-md-3 col-lg-2 col-6 margin-15px-bottom" key={image.id}>
          <a href={linkUrl} target="_blank" className="uniPartnerLogo">
            <div className="uni-image-box">
              <img
                src={image.acf.university_logo.source_url}
                alt={image.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}
              />
            </div>
            <div className="uni-text-box">
              <span className="w-100">{image.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}</span>
            </div>
          </a>
        </div>
      );
    })}
  </div>

  <div className="row remaining-universities home-university-partners-detail">
    {university_logo_slider.slice(6).map((image) => {
      const linkUrl = image.acf.university_detail_page?.includes("yes")
        ? `/university-detail/${image.slug}/`
        : image.acf.website_url || "";

      return (
        <div className="col-md-3 col-lg-2 col-6 margin-15px-bottom" key={image.id}>
          <a href={linkUrl} target="_blank" className="uniPartnerLogo">
            <div className="uni-image-box">
              <img
                src={image.acf.university_logo?.source_url}
                alt={image.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}
              />
            </div>
            <div className="uni-text-box">
              <span className="w-100">{image.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')}</span>
            </div>
          </a>
        </div>
      );
    })}

    <div className="col-md-12 university-partners-footer">
      <div className="text-smallest margin-20px-top last-paragraph-no-margin">
        {ReactHtmlParser(our_university_partners_footer)}
      </div>
    </div>
  </div>

  <div className="col-12 text-center mt-4">
    <a onClick={(e) => this.homeOurUniPartLink(e)} className="view-more-universities-btn">
      View More Universities +
    </a>
  </div>
</div>

              </div>
            </section>
            <div class="divider"></div>

         



            <InstagramReels
  instagramSectionTitle={instagram_section_title}
  instagramLink={instagram_link} 
/> 

          </div>
        </Layout>
      </div>
    )
  }
}

export default Home;

export const pageQuery = graphql`
query MyQueryNewNew {
  allWordpressPage(filter: {wordpress_id: {in: 12}}) {
    nodes {
      title
      id
      wordpress_id
      date
      excerpt
      guid
      status
      acf {
        meta_title
        meta_description
        page_title
      
        awards_and_recognitions_title
        awards_and_recognitions {
          title
          detail
          link
          award_image {
            id
            source_url
          }
        }
        our_track_record_title
        students_educated_count
        students_educated_text
        tution_fees_saved_number
        tution_fees_saved_text
        satisfaction_rating_text
        
        visa_success_rate_text
        overseas_degrees_count
        overseas_degrees_text
        our_track_record_footer
        our_smart_program_title
        our_smart_program_detail
        our_smart_program_image {
          id
          source_url
        }
        our_smart_program_youtube_link
        our_smart_program_button_text
        our_smart_program_button_link
          news {
news_item
    }
        journey_process_title
  
        instagram_section_title
        instagram_posts {
          image{
        	  source_url
          }
          link
        }
        instagram_link
        our_university_partners_title
        our_university_partners_footer
 featured_universities_ids
        focus_keyphrase
        canonical_url
        dynamic_schema
        additional_schema_1
        additional_schema_2
        additional_schema_3
        additional_schema_4
     
        slider {
          slide_link
          slide_alt_text
          slide_image {
            id
            source_url
          }
        }
    
       
    whats_new_title
           whats_new_blocks {
         title
         description
         button_title
         button_url
         tag
        image {
source_url
        }
      }
        
        process_steps {
          process_step_title
          process_step_description
        }
        testimonial_heading
        

     
  
      }
      
    }
  }
  allWordpressWpTestimonial(filter: {acf: {show_on_page: {eq: "Home"}}}) {
    edges {
      node {
        wordpress_id
        title
        content
        featured_media {
          id
          source_url
        }
        status
        slug
        acf {
          batch_of_year
          board_and_percentage_obtained
          scholership_image  {
            source_url
          }
          program_name
          review_rating
          state
          show_on_page
          desktop_sequencing
          mobile_sequencing
          small_image {
          source_url
          }
          youtube_video_link
          country_flag {
            source_url
          }
        }
      }
    }
  }

  allWordpressWpNews(sort: {order: ASC, fields: id}) {
    edges {
      node {
        id
        date
        wordpress_id
        type
        title
        status
        slug
        menu_order
        content
        featured_media {
          source_url
          id
          type
          wordpress_id
          title
        }
        acf {
          news_date
          news_content_link
          news_content
        }
      }
    }
  }
 allWordpressWpUniversities{
				nodes {
				  id
          	  wordpress_id
				  slug
				  title
				  acf {
					country
          website_url
          university_detail_page
					university_logo {
          source_url
        }
				  }
				}
			  }
}
`