import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet'; 

import '../../css/rahul-styles.css';
import '../../css/style.css';
import validator from 'validator';
import swal from 'sweetalert';
import Layout from "../../components/layout";



const EventDetailsPage = (params) => {
  const { slug } = params;

    const [selected, setSelected] = useState([]);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [qualification, setQualification] = useState('')
  const [destination, setDestination] = useState('')
  const [abroad, setAbroad] = useState('')

  const [questions, setQuestions] = useState('')
  const [policy, setPolicy] = useState(false) 
  const [formError, setFormError] = useState({})
  const errors = {}

  const handleChange = (e) => {
    if(e.target.name === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'number') {
      setNumber(e.target.value)
    } else if (e.target.name === 'qualification') {
      setQualification(e.target.value)
    } else if (e.target.name === 'destination') {
      setDestination(e.target.value)
    } else if (e.target.name === 'questions') {
      setQuestions(e.target.value)
    } else if (e.target.name === 'abroad') {
      setAbroad(e.target.value)
    }
  }

  const handlePolicy = (e) => {
    const policyInput = e.target.checked 
    setPolicy(policyInput) 
  }

  const runValidations = () => {
    if(firstName.trim().length === 0) {
      errors.firstName = "Required"
    } 
 
    if (email.trim().length === 0) {
      errors.email = "Required"
    } else if (!validator.isEmail(email)) {
      errors.email = "Invalid E-Mail Format"
    }
    if (number.trim().length === 0) {
      errors.number = "Required"
    } else if (number.trim().length < 10) {
      errors.number = "Atleast 10 digits required"
    } 
    if (!qualification) {
      errors.qualification = "Required"
    }
    if (!destination) {
      errors.destination = "Required"
    }
   
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    runValidations()



    if(Object.keys(errors).length === 0) {
      setFormError({})
      const formData = {
        firstName: firstName,
   
        email: email,
        number: number,
        destination: destination,

        qualification: qualification,

        policy: policy,

      }


      setFirstName('')

      setEmail('')
      setNumber('')
      setDestination('')
    
      setQualification('')
   
      setPolicy(false)

     

 

    

      axios.post('https://assets.theworldgrad.com/wp-json/custom-leads/v1/store', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
       
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": qualification
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": destination 
        },
    
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
     
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy
        },
        {
          "Attribute": "mx_Lead_Captured_at",
          "Value": new Date().toISOString() // Sets current date and time in ISO format
        },
        {
          "Attribute": "lead_category",
          "Value": "Events"
        },
        {
          "Attribute": "Source",
          "Value": `${post?.title ? `Event: ${post.title}` : "Event Request"}` // Dynamically setting the event title
        },
        {
          "Attribute": "SourceUrl",
          "Value": window.location.href // Captures the current page URL
        }
      ])
      .then(response => {

        if (response.data && response.data.Status === "Success") {
          swal("Done!", "Our team will get in touch with you soon.", "success");
      } else {
          swal("Oops!", "There was an issue saving your details. Please try again.", "error");
      }

      })
      .catch(err => {
        alert(err.message)
      
      })

    } else {
      setFormError(errors)
    }
  }

  





function customParseDate(dateString) {
  const months = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12'
  };
  

  const [datePart, timePart, period] = dateString.split(' ');
  

  const [year, monthName, day] = datePart.split('-');

  const [hours, minutes, seconds] = timePart.split(':');
  

  const month = months[monthName];
  

  let hour = parseInt(hours);
  if (period === 'PM' && hour < 12) {
    hour += 12;
  }
  if (period === 'AM' && hour === 12) {
    hour = 0;
  }
  

  const isoDateString = `${year}-${month}-${day}T${hour.toString().padStart(2, '0')}:${minutes}:${seconds}`;
  

  return new Date(isoDateString);
  }


  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const fetchEventData = async () => {
      let eventId;
      let eventSlug;
      const urlParams = new URLSearchParams(window.location.search);
      const urlEventId = urlParams.get('id');

      if (!slug) {
          try {
              console.warn("Event ID is missing in the URL, fetching the most recent event.");
              const recentEventResponse = await axios.post('https://assets.theworldgrad.com/graphql', {
                  query: `
                      query {
                           events(first: 1) {
                              edges {
                                  node {
                                      id
                                      databaseId
                                  }
                              }
                          }
            
                      }`
              });
      
              // Log the entire response to inspect its structure
              console.log("API Response:", recentEventResponse.data);
      
              // Check for errors in the response
              if (recentEventResponse.data.errors) {
                  console.error("GraphQL Errors:", recentEventResponse.data.errors);
                  setLoading(false);
                  return;
              }
      
              const recentEventData = recentEventResponse.data.data?.events?.edges;
              if (!recentEventData || recentEventData.length === 0) {
                  console.error("No events found or unexpected response structure.");
                  setLoading(false);
                  return;
              }
      
              eventId = recentEventData[0].node.databaseId;
              eventSlug = recentEventData[0].node.slug;
           
      
          } catch (error) {
              console.error("Error fetching the most recent event:", error);
              setLoading(false);
              return;
          }
      } else {
          eventId = urlEventId;
          eventSlug = slug;
      
      }
      


 

      try {
        const response = await axios.post('https://assets.theworldgrad.com/graphql', {
          query: `
          query {
              events(where: {name: "${eventSlug}"}) {
                edges {
                  node {
                    id
                    slug
                    title
                    content
                    eventsPost {
                      eventDate
                      eventEndDate
                      eventLocation
                      eventSubtitle
                      onlineLink {
                        target
                        title
                        url
                      }
                      eventBanner {
                        sourceUrl
                      }
                    }
                    featuredImage {
                      node {
                        sourceUrl
                      }
                    }
                    seoDetails {
                      canonicalUrl
                      dynamicSchema
                      focusKeyphrase
                      metaDescription
                      metaTitle
                    }
                  }
                }
              }
            }`
        });

   
        if (response.data.data.events.edges.length > 0) {
          const post = response.data.data.events.edges[0].node;

          setPost(post);
          setLoading(false);
          const webinarStartDate = post.eventsPost.eventDate;
          const webinarEndDate = post.eventsPost.eventEndDate;
        
          const dateObj = customParseDate(webinarEndDate ? webinarEndDate : webinarStartDate);
      
      
          if (isNaN(dateObj.getTime())) {
              console.error('Invalid date:', webinarStartDate, webinarEndDate );
          } else {
           
              const endDate = dateObj.getTime();
        
              const timer = setInterval(function() {
                  const now = new Date().getTime();
                
                  const t = parseInt(endDate - now);
                          
                  if (t > 0) {
                
                      let days = Math.floor(t / (1000 * 60 * 60 * 24));
                      let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                      let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                      let secs = Math.floor((t % (1000 * 60)) / 1000);
      
                  
                      const timerDaysElement = document.getElementById("timer-days");
                      if (timerDaysElement) {
                          timerDaysElement.innerHTML = days + "<span class='label'>DAYS</span>";
                      }
                      const timerHoursElement = document.getElementById("timer-hours");
                      if (timerHoursElement) {
                          timerHoursElement.innerHTML = ("0" + hours).slice(-2) + "<span class='label'>HRS</span>";
                      }
                      const timerMinsElement = document.getElementById("timer-mins");
                      if (timerMinsElement) {
                          timerMinsElement.innerHTML = ("0" + mins).slice(-2) + "<span class='label'>MIN</span>";
                      }
                      const timerSecsElement = document.getElementById("timer-secs");
                      if (timerSecsElement) {
                          timerSecsElement.innerHTML = ("0" + secs).slice(-2) + "<span class='label'>SEC</span>";
                      }
                  } else {
       
                      const timerElement = document.getElementById("timer");
                      if (timerElement) {
                          timerElement.innerHTML = "Event is expired!";
                      }
                      clearInterval(timer); 
                  }
              }, 100);
      
        
              return () => clearInterval(timer);
          }
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
        setLoading(false);
      }
    };

    fetchEventData();
  }, []);

  if (typeof window === 'undefined' || loading  )  {
    return <loader> <div >Loading events details...</div> </loader>;
  }

  if (!post) {
    return <div>No event data available</div>;
  }

  const { title, eventsPost, content, seoDetails } = post;
  const { eventDate, eventEndDate, eventLocation, eventSubtitle, onlineLink, eventBanner } = eventsPost;
  const { canonicalUrl, dynamicSchema, focusKeyphrase, metaDescription, metaTitle } = seoDetails;
  const headerBackground = eventBanner?.sourceUrl;

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // special case for 11th to 13th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  
  const formatDate = (dateString) => {
  
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month name
    const ordinalSuffix = getOrdinalSuffix(day);
  
    return (
      <span>
        {day}
        <span className="nth-lowercase">{ordinalSuffix}</span> {month}
      </span>
    );
  };




  return (
    <div>
      <Layout>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          {focusKeyphrase && <meta name="keywords" content={focusKeyphrase} />}
          <link rel="canonical" href={canonicalUrl} />
          {dynamicSchema && <script type="application/ld+json">{JSON.stringify(JSON.parse(dynamicSchema))}</script>}
        </Helmet>

        <div>
          <div className="newStyle font-poppins">
            <div className="container">
              <ol className="transparentbreadcrumb breadcrumb breadcrumb-new">
                <li className="breadcrumb-item">
                  <a href="/">Home </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/event-listing/">Events </a>
                </li>
                <li className="breadcrumb-item active" dangerouslySetInnerHTML={{ __html: title }} />
              </ol>
            </div>

            <section className="wow animate__fadeIn bg-light-gray position-relative extra-big-section event-banner" style={{ backgroundImage: `url(${headerBackground})` }}>
              <div className="bg-medium-slate-blue"></div>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large"></div>
                </div>
              </div>
            </section>

            <div className="container-fluid p-0">
              <div className="row m-0">
                <div className="p-0 col-12">
                  <div className="blog-details-img-wrap event-details-img-wrap">
                    <div className="blog-detail-content">
                      <div className="container">
                        {eventLocation === "Online" && (
                          <a className="btn btn-warning online-link" href={onlineLink?.url} target={onlineLink?.target} dangerouslySetInnerHTML={{ __html: onlineLink?.title }} />
                        )}

<p className={`event-date ${eventEndDate ? 'event-end-date' : ''}`}>
  <span>{formatDate(eventDate)}
  {eventEndDate && (
    <>
      
     - {formatDate(eventEndDate)}
    </>
  )}
  </span>
</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container py-5">
              <div className="row pb-5">
                <div className="col-lg-6 updated-blog-details">
                  <h1 className="alt-font font-weight-500">{title}</h1>
                  <div className="alt-font text-white no-margin-bottom" dangerouslySetInnerHTML={{ __html: eventSubtitle }} />
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>

                <div className="col-lg-6 pl-lg-5 mt-lg-0 mt-5 blog-detail-form-wrap text-center">
                  <p id="timer">
                    <span id="timer-days"></span>
                    <span id="timer-hours"></span>
                    <span id="timer-mins"></span>
                    <span id="timer-secs"></span>
                  </p>
                  <div className="row top-row-img">
                    <div className="col-12 w-100 p-0">
                      <div className="header-text">
                        <h2 className="main-heading-text">Register Here</h2>
                      </div>
                    </div>
                  </div>
                  <div className="container form-container text-left">
                    <div className="row">
                      <div className="col-12 mb-1">
                        <p className="sub-heading mb-0 text-color">
                          PERSONAL
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label form-label-popup">NAME*</label> {formError.firstName &&  <span className="text-danger error-message-text"> {formError.firstName} </span> }
                        <input
                          name='firstName'
                          value={firstName}
                          onChange={handleChange}
                          type="text"
                          className="form-control form-control-popup text-color" placeholder="Type Here"
                        />
                      </div>
                    
                      <div className="col-md-6">
                        <label className="form-label form-label-popup">E-MAIL*</label> {formError.email && <span className="text-danger error-message-text"> {formError.email} </span>}
                        <input
                          name='email'
                          value={email}
                          onChange={handleChange}
                          type="email"
                          className="form-control form-control-popup"
                          placeholder="Type Here"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label form-label-popup">PHONE NUMBER*</label> {formError.number && <span className="text-danger error-message-text"> {formError.number} </span>}
                        <input
                          name='number'
                          value={number}
                          onChange={handleChange}
                          type="number"
                          className="form-control form-control-popup"
                          placeholder="+91"
                        />
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 mb-1">
                        <p className="sub-heading mb-0 text-color"> EDUCATION </p>
                      </div>
                      <div className="col-md-6">
        
                        <label className="form-label form-label-popup"> QUALIFICATION YOU'RE SEEKING* </label>
        
                        <select
                          className="form-select form-select-popup"
                          name="qualification"
                          value={qualification}
                          onChange={handleChange}
                        >
                          <option selected> Select One </option>
                          <option value="Undergraduate"> Undergraduate </option>
                          <option value="Postgraduate"> Postgraduate </option>
                        </select>
                        {formError.qualification && <span className="text-danger error-message-text"> {formError.qualification} </span>}
                      </div>
                      <div className="col-md-6">
        
                        <label className="form-label form-label-popup"> PREFERRED EDUCATION DESTINATION* </label>
                        <select
                          name="destination"
                          value={destination}
                          onChange={handleChange}
                          className="form-select form-select-popup"
                        >
                          <option selected> Select One </option>
                          <option value="Australia"> Australia </option>
                          <option value="Dubai"> Dubai </option>
                          <option value="United Kingdom"> United Kingdom </option>
                          <option value="United States"> United States </option>
                          <option value="Other"> Other </option>
                        </select>
                        {formError.destination && <span className="text-danger error-message-text"> {formError.destination} </span>}
                      </div>
               
                      <div className="col-md-6 multi-select-container">
                       
                      </div>
                    
                    </div>
                    <div className="row">
        
                    
                      <div className="col-12">
        
                        <label className="form-check-label text-very-small" style={{ fontWeight: "500" }} >
                          <input
                            checked={policy}
                            onChange={handlePolicy}
                            className="form-check-input"
                            type="checkbox"
                          />
                          I have read and understood <a href="/privacypolicy"> The WorldGrad Privacy Policy </a>
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-4 button-body">
                        <a href="#">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={handleSubmit}
                            disabled={!policy}
                          >SUBMIT</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Form for registration */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default EventDetailsPage;
